import DirectionsIcon from '@mui/icons-material/Directions';
import PhoneIcon from '@mui/icons-material/Phone';
import WebIcon from '@mui/icons-material/Web';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Typography
} from '@mui/material';

import { Close } from '@mui/icons-material';
import { useContext, useState } from 'react';
import { UserContext } from '../../Contexts/UserContext';
import { LocationContext } from '../../Contexts/UserLocationContext';
import { httpClient } from '../../Interceptors/httpClient';
import { CuisineIconList } from '../Icons/Icon';
import { FavoriteIcon } from '../RestaurantCard/FavoriteIcon';
import { HoursOfOperationButton } from '../RestaurantCard/HoursOfOperationButton';
import { MilesAway } from '../RestaurantCard/MilesAway';
import { RestaurantHappyHour } from '../RestaurantCard/RestaurantHappyHour';
import { haversineDistance } from '../RestaurantCard/haversineDistance';
import { RestaurantDialogProps } from './RestaurantDialogProps';
import { ZoomTransition } from './ZoomTransition';

export const RestaurantDialog = ({ restaurant, open, onClose }: RestaurantDialogProps) => {
    const { user, setUser } = useContext(UserContext);
    const { location } = useContext(LocationContext);
    const [isFavorite, setIsFavorite] = useState<Map<string, boolean>>(new Map());
    let distance: number | undefined = undefined;
    if (location) {
        distance = haversineDistance(
            location.latitude,
            location.longitude,
            restaurant.latitude,
            restaurant.longitude
        );
    }

    const toggleFavorite = async (event: React.MouseEvent<HTMLElement, MouseEvent> | React.TouchEvent<HTMLElement>, restaurantId: string) => {
        if (!user) {
            return;
        }
        if (isFavorite.get(restaurantId)) {
            return;
        }
        setIsFavorite((prev) => {
            const newMap = new Map(prev);
            newMap.set(restaurantId, !newMap.get(restaurantId));
            return newMap;
        });
        try {
            const url = user.favoriteRestaurants?.some((r) => r.id === restaurantId) ? 'favorites/remove' : 'favorites/add';
            const response = await httpClient.post(`${url}`, {
                userId: user?.id,
                restaurantId: restaurantId
            });

            if (response.status === 200) {
                const userRestaurants = user.favoriteRestaurants;
                if (userRestaurants) {
                    if (userRestaurants.some((r) => r.id === restaurantId)) {
                        userRestaurants.splice(userRestaurants.findIndex((r) => r.id === restaurantId), 1);
                    } else {
                        userRestaurants.push(restaurant);
                    }
                } else {
                    user.favoriteRestaurants = [restaurant];
                }
                const newUser = { ...user };
                newUser.favoriteRestaurants = userRestaurants;
                setUser(newUser);
            }
        } catch (error) {
            // Handle error
        }
        setIsFavorite((prev) => {
            const newMap = new Map(prev);
            newMap.set(restaurantId, !newMap.get(restaurantId));
            return newMap;
        });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            PaperProps={{
                sx: {
                    margin: '0'
                }
            }}
            TransitionComponent={ZoomTransition}
        >
            <DialogTitle sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <Box sx={{
                    width: 'calc(100% - 28px)',
                }}
                >
                    <Typography
                        variant="h5">
                        {restaurant.name}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color="text.secondary">
                        {restaurant.address}, {restaurant.city}, {restaurant.state} {restaurant.zip}
                    </Typography>
                </Box>
                <Box sx={{
                    flexGrow: 1
                }} />
                <Close sx={{
                    mr: 1,
                    cursor: 'pointer',
                    height: '100%',
                    '&:hover': {
                        backgroundColor: (theme) => theme.palette.action.hover,
                        borderRadius: '50%'
                    }
                }}
                    onClick={onClose} />
                <FavoriteIcon
                    sx={{
                        display: 'flex'
                    }}
                    isFavorite={user?.favoriteRestaurants?.some((r) => r.id === restaurant.id) ?? false}
                    onClick={toggleFavorite}
                    isUpdating={isFavorite.get(restaurant.id)}
                    restaurantId={restaurant.id}
                />
            </DialogTitle>
            <DialogContent dividers>
                {restaurant.description && (
                    <Box sx={{ overflow: 'auto', mb: 2 }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Description
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            {restaurant.description}
                        </Typography>
                    </Box>
                )}
                <Box sx={{ overflow: 'auto', mb: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Happy Hour
                    </Typography>
                    <RestaurantHappyHour restaurant={restaurant} hideOverflow={false} />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    justifyContent: 'space-between',
                }}>
                    <Box>
                        <Box
                            sx={{
                                mb: 1
                            }}
                        >
                            <HoursOfOperationButton restaurant={restaurant} />
                        </Box>
                        {restaurant.happyHourUrl && (
                            <Link href={restaurant.happyHourUrl}
                                color="secondary"
                                target="_blank"
                            >
                                Source
                            </Link>
                        )}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-end'
                    }}>
                        {restaurant.restaurantCuisines?.length > 0 && (
                            <CuisineIconList cuisines={restaurant.restaurantCuisines} />
                        )}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{ width: '100%' }}>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    gap: 1,
                }}>
                    <Button
                        size="small"
                        href={`https://www.google.com/maps/dir/?api=1&destination=${restaurant.address}, ${restaurant.city}, ${restaurant.state} ${restaurant.zip}`}
                        target="_blank"
                        rel="noreferrer"
                        color='primary'
                        variant='outlined'
                    >
                        <DirectionsIcon
                            sx={{ mr: 0.5 }}
                            fontSize="small" />
                        Directions
                    </Button>
                    <Button
                        size="small"
                        href={`tel:${restaurant.phone}`}
                        target="_blank"
                        rel="noreferrer"
                        color='primary'
                        variant='outlined'
                    >
                        <PhoneIcon
                            sx={{ mr: 0.5 }}
                            fontSize="small" />
                        Call
                    </Button>
                    <Button
                        size="small"
                        href={restaurant.website}
                        target="_blank"
                        rel="noreferrer"
                        color='primary'
                        variant='outlined'
                    >
                        <WebIcon sx={{ mr: 0.5 }}
                            fontSize="small" />
                        Website
                    </Button>
                    <Box sx={{ flexGrow: 1 }} />
                    {distance && (<MilesAway distance={distance} />)}
                </Box>
            </DialogActions>
        </Dialog>
    );
};
