import { FC } from "react";
import { Route, Routes } from 'react-router-dom';
import { NotFound } from "../NotFound/NotFound";
import { AdminHappyHour } from "./HappyHour/AdminHappyHour";
import { RestaurantsAdmin } from "./Restaurant/RestaurantsAdmin";
import { RestaurantZipQueuePage } from "./RestaurantZipQueue/RestaurantZipQueuePage";
import { UsersAdmin } from './Users/UsersAdmin';
import { ZipCode } from "./ZipCode/ZipCode";

export const Admin: FC = () => {
    return (
        <Routes>
            <Route path='users' element={<UsersAdmin />} />
            <Route path='restaurants' element={<RestaurantsAdmin />} />
            <Route path='zipCode' element={<ZipCode />} />
            <Route path='restaurantZipQueue' element={<RestaurantZipQueuePage />} />
            <Route path='happyHour' element={<AdminHappyHour />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}