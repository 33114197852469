import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpClient } from "../../../Interceptors/httpClient";

export interface ZipCodeFormDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (zipCode: string) => void;
}
export const ZipCodeFormDialog = (props: ZipCodeFormDialogProps) => {
    const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (formik.isSubmitting) {
            return;
        }
        formik.resetForm();
        props.onClose();
    };

    const formik = useFormik({
        initialValues: {
            zipCode: '',
        },
        validationSchema: Yup.object({
            zipCode: Yup.string()
                .required('Required')
                .matches(/^\d{5}$/, 'Must be a valid zip code'),
        }),
        onSubmit: async (values) => {
            await httpClient.post('/restaurant/zip/' + values.zipCode);
            // Reset the form
            formik.resetForm();
            props.onSubmit(values.zipCode);
        },
    });

    return (
        // Prevent the dialog from closing when the user clicks outside of it
        <Dialog
            open={props.open}
            onClose={handleClose}
            disableEscapeKeyDown
        >
            <DialogTitle>Create by Zip Code</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Enter a zip code to create restaurants for that zip code.
                </DialogContentText>
                <TextField
                    margin="dense"
                    id="zipCode"
                    label="Zip Code"
                    type="text"
                    fullWidth
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.zipCode && formik.touched.zipCode}
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="inherit"
                    variant="outlined"
                    disabled={formik.isSubmitting}
                >
                    Cancel
                </Button>
                <Button
                    onClick={formik.submitForm}
                    color="primary"
                    variant="contained"
                    disabled={!formik.isValid || formik.isSubmitting}
                >
                    {formik.isSubmitting ? 'Creating...' : 'Create'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};