import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import debounce from 'lodash/debounce';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { httpClient } from '../../../Interceptors/httpClient';
import { User } from '../../../Models/Auth/user.model';
import { Order, TableUser, UserAdminTableHead } from './UserAdminTableHead';
import { UserAdminTableToolbar } from './UserAdminTableToolbar';
import { UserModifyDialog } from './UserModifyDialog';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string | boolean | string[] },
    b: { [key in Key]: number | string | boolean | string[] },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const UsersAdmin: FC = () => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof TableUser>('email');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [users, setUsers] = useState<TableUser[]>([]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User>();
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        if (search.length < 2) {
            setUsers([]);
            return;
        }
        setIsLoading(true);
        httpClient.get('/identity/getUsers', {
            params: {
                page: page + 1,
                pageSize: rowsPerPage,
                email: search,
            }
        }).then(res => {
            setUsers(res.data.items);
            setTotalCount(res.data.totalCount);
        }).catch(err => {
            //
        }).finally(() => {
            setIsLoading(false);
        });
    }, [page, rowsPerPage, search]);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof TableUser,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        setSelectedUser(users.find(u => u.id === id) as User);
        setOpenDialog(true);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const debouncedSearch = debounce((criteria: string) => {
        setSearch(criteria);
    }, 300);

    const handleSearchChange = (value: string) => {
        debouncedSearch(value);
    }

    return (
        <div className='flex flex-col h-full p-4'>
            <Box sx={{
                width: '100%',
            }}>
                <Paper sx={{
                    width: '100%',
                    mb: 2,
                }}>
                    <UserAdminTableToolbar
                        onSearchChange={handleSearchChange}
                        isLoading={isLoading}
                    />
                    {search.length >= 2 && (
                        <>
                            <TableContainer>
                                <Table
                                    sx={{
                                        minWidth: 750
                                    }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                >
                                    <UserAdminTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={users.length}
                                    />
                                    <TableBody>
                                        {users.slice().sort(getComparator(order, orderBy) as any)
                                            .map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => handleClick(event, row.id)}
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="normal"
                                                        >
                                                            {row.id}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="normal"
                                                        >
                                                            {row.email}
                                                        </TableCell>
                                                        <TableCell
                                                            component={'th'}
                                                            id={labelId}
                                                            scope="row"
                                                            padding="normal"
                                                        >
                                                            {row.firstName}
                                                        </TableCell>
                                                        <TableCell
                                                            component={'th'}
                                                            id={labelId}
                                                            scope="row"
                                                            padding="normal"
                                                        >
                                                            {row.lastName}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="normal"
                                                        >
                                                            {row.lockoutEnabled ? 'Yes' : 'No'}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="normal"
                                                        >
                                                            {row.roles.join(', ')}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    )}
                    {search.length < 2 && (
                        <div className='pb-2'>
                            <Typography
                                variant="h6"
                                gutterBottom
                                align="center"
                                m={2}
                            >
                                Search for users by email address. Must use at least 2 characters.
                            </Typography>
                        </div>
                    )}
                    {/* Show no results found message if search.length is 2 or greater and no users found */}
                    {search.length >= 3 && users.length === 0 && (
                        <div className='pb-2'>
                            <Typography
                                variant="h6"
                                gutterBottom
                                align="center"
                                marginBottom={2}
                            >
                                No users found.
                            </Typography>
                        </div>
                    )}
                </Paper>
            </Box>
            <UserModifyDialog
                open={openDialog}
                user={selectedUser}
                onClose={(user?: User) => {
                    // Replace the selected user with the updated user.
                    if (user) {
                        const index = users.findIndex(u => u.id === user.id);
                        if (index >= 0) {
                            const newUsers = [...users];
                            newUsers[index] = user;
                            setUsers(newUsers);
                        }
                    }
                    setOpenDialog(false);
                }}
            />
        </div>
    );
}
