import { useTheme } from '@mui/material';
import L from 'leaflet';
import { useEffect } from 'react';
import { Tooltip as LeafletTooltip, MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';
import { Restaurant } from "../../Models/Restaurant/Restaurant";

export interface RestaurantMapProps {
    restaurants: Restaurant[];
    location?: GeolocationCoordinates;
    highlightedRestaurantId?: string;
}

const blueMarkerSVG = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(`
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
  <defs>
    <linearGradient id="blueGradient" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop offset="0%" style="stop-color:#004cff;stop-opacity:1" />
      <stop offset="100%" style="stop-color:#00d4ff;stop-opacity:1" />
    </linearGradient>
  </defs>
  <path fill="url(#blueGradient)" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
  <circle cx="12" cy="9" r="2.5" fill="#fff"/>
</svg>`)}`;

const greenMarkerSVG = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(`
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
  <defs>
    <linearGradient id="greenGradient" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop offset="0%" style="stop-color:#00FF00;stop-opacity:1" />
      <stop offset="100%" style="stop-color:#00cc66;stop-opacity:1" />
    </linearGradient>
  </defs>
  <path fill="url(#greenGradient)" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
  <circle cx="12" cy="9" r="2.5" fill="#fff"/>
</svg>`)}`;

const blueIcon = new L.Icon({
    iconUrl: blueMarkerSVG,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const greenIcon = new L.Icon({
    iconUrl: greenMarkerSVG,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const injectLeafletStyles = (isDarkMode: boolean) => {
    const styleId = 'leaflet-dark-mode-styles';
    let styleElement = document.getElementById(styleId);

    if (isDarkMode) {
        if (!styleElement) {
            styleElement = document.createElement('style');
            styleElement.id = styleId;
            styleElement.innerHTML = `
                .leaflet-layer,
                .leaflet-control-zoom-in,
                .leaflet-control-zoom-out,
                .leaflet-control-attribution {
                    filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
                }
            `;
            document.head.appendChild(styleElement);
        }
    } else {
        if (styleElement) {
            document.head.removeChild(styleElement);
        }
    }
};

const UpdateMapBounds = ({ restaurants, location }: { restaurants: Restaurant[], location?: GeolocationCoordinates }) => {
    const map = useMap();

    useEffect(() => {
        const bounds = new L.LatLngBounds(
            location ? [location.latitude, location.longitude] : [0, 0],
            location ? [location.latitude, location.longitude] : [0, 0]
        );

        restaurants.forEach((restaurant) => {
            bounds.extend([restaurant.latitude, restaurant.longitude]);
        });

        map.fitBounds(bounds);

    }, [restaurants, location, map]);

    return null;
};

export const RestaurantMap = (props: RestaurantMapProps) => {
    const { restaurants, location, highlightedRestaurantId } = props;
    const tileLayerUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    const theme = useTheme();

    useEffect(() => {
        injectLeafletStyles(theme.palette.mode === 'dark');
        return () => injectLeafletStyles(false);
    }, [theme.palette.mode]);

    return (
        <MapContainer
            style={{
                width: '100%',
                height: '100%',
            }}
            center={location ? [location.latitude, location.longitude] : [0, 0]}
            zoom={location ? 15 : 2}
            scrollWheelZoom={true}
        >
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url={tileLayerUrl}
            />
            <UpdateMapBounds restaurants={restaurants} location={location} />
            {location && (
                <Marker
                    position={[location.latitude, location.longitude]}
                    icon={greenIcon}
                >
                    <LeafletTooltip permanent>
                        You are here
                    </LeafletTooltip>
                </Marker>
            )}
            {restaurants.map((restaurant) => (
                <Marker
                    key={restaurant.id + (restaurant.id === highlightedRestaurantId).toString()}
                    position={[restaurant.latitude, restaurant.longitude]}
                    icon={restaurant.id === highlightedRestaurantId ? greenIcon : blueIcon}
                >
                    <LeafletTooltip permanent={restaurant.id === highlightedRestaurantId}>
                        {restaurant.name}
                    </LeafletTooltip>
                </Marker>
            ))}
        </MapContainer>
    );
};