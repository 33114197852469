import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { FC, useRef, useState } from "react";
import * as yup from "yup";
import { ConfirmDialog } from "../../../Components/ConfirmDialog/ConfirmDialog";
import { httpClient } from "../../../Interceptors/httpClient";
import { ZipCodeAreaAutocomplete } from "./ZipCodeAreaAutocomplete";
import { ZipCodeAreaAutocompleteRef } from "./ZipCodeAreaAutocompleteRef";
import { ZipCodeResult } from "./ZipCodeResult";

export const ZipCodeByAreaTab: FC = (): JSX.Element => {
    const [open, setOpen] = useState(false);
    const [zipCodes, setZipCodes] = useState<string[]>([]);
    const [queuing, setQueuing] = useState(false);
    const autocompleteRef = useRef<ZipCodeAreaAutocompleteRef>(null);

    const clearAutocomplete = () => {
        autocompleteRef.current?.clear();
    };

    const validationSchema = yup.object({
        zipCodeResult: yup.object().nullable().required("Area is required"),
    });

    const formik = useFormik<{ zipCodeResult: ZipCodeResult | null }>({
        initialValues: {
            zipCodeResult: null,
        },
        validateOnMount: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const response = await httpClient.get(`zipCodes/area?resultType=${values.zipCodeResult?.resultType}&value=${values.zipCodeResult?.value}`);

            setZipCodes(response.data);
            setOpen(true);
        }
    });

    return (
        <>
            <Box sx={{
                padding: "1rem"
            }}>
                <Typography sx={{ marginBottom: .5 }}
                    variant="body2">
                    Select an area to queue all zip codes for that area.
                </Typography>
                <ZipCodeAreaAutocomplete
                    ref={autocompleteRef}
                    onSelect={(v: ZipCodeResult | null) => {
                        formik.setFieldValue("zipCodeResult", v);
                    }}
                />
                <Button
                    sx={{ marginTop: 1 }}
                    variant="contained"
                    onClick={() => formik.handleSubmit()}
                    disabled={!formik.isValid || queuing}
                >
                    Submit
                </Button>
            </Box>
            <ConfirmDialog
                id="confirm-zip-code-dialog"
                open={open}
                disabled={queuing}
                title="Confirm"
                confirmButtonText={queuing ? "Queuing..." : "Queue"}
                message={`Are you sure you would like to queue the following zip codes: ${zipCodes.join(", ")}?`}
                onConfirm={async () => {
                    if (zipCodes?.length === 0) {
                        return;
                    }
                    setQueuing(true);
                    try {
                        await httpClient.post('restaurant/zip', zipCodes);
                        setOpen(false);
                        enqueueSnackbar("Zip codes queued successfully", {
                            variant: "success",
                        });
                        clearAutocomplete();
                    } catch (error) {
                        enqueueSnackbar("An error occurred", {
                            variant: "error",
                        });
                    }
                    setQueuing(false);
                }}
                onCancel={() => {
                    setOpen(false);
                }}
            />
        </>
    );
}