import { Box, Button, List, ListItem, ListItemText, Popover, Typography } from "@mui/material";
import { useState } from "react";
import { HoursOfOperation } from "../../Models/Restaurant/HoursOfOperation";
import { Restaurant } from "../../Models/Restaurant/Restaurant";
import { convertDayOfWeekToString } from "../../Pages/Admin/HoursOfOperation/convertDayOfWeekToString";
import { convertTimeStampTo12HourTime, getDateFromUtcTimeStamp, getMinutesUntilFormatted, isClosingTimeLessThanOneHourAway, isCurrentTimeBetween, isCurrentTimeLessThanOneHourAwayFrom } from "../../Utilities/TimeStampConverterUtility";

export const HoursOfOperationButton = (props: { restaurant: Restaurant }) => {
    const { restaurant } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const currentDayHoursOfOperation = restaurant.hoursOfOperation.find(
        (hours) => hours.dayOfWeek === new Date().getDay()
    );

    const openNow = currentDayHoursOfOperation
        ? isCurrentTimeBetween(
            currentDayHoursOfOperation.openTime,
            currentDayHoursOfOperation.closeTime
        )
        : false;

    const currentDayOpeningTimeMinusOneHour = currentDayHoursOfOperation
        ? getDateFromUtcTimeStamp(currentDayHoursOfOperation.openTime)
        : undefined;

    const openSoon = currentDayOpeningTimeMinusOneHour
        ? isCurrentTimeLessThanOneHourAwayFrom(
            currentDayOpeningTimeMinusOneHour
        )
        : false;

    const closingSoon = currentDayHoursOfOperation
        ? isClosingTimeLessThanOneHourAway(
            currentDayHoursOfOperation.closeTime
        )
        : false;

    const isAnyDayOpen = restaurant.hoursOfOperation.some(
        (hours) => hours.openTime && hours.closeTime
    );

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const renderHoursOfOperation = (hours: HoursOfOperation[]) => {
        const today = new Date().getDay();
        return (
            <List sx={{
                padding: 0,
                margin: 0,
            }}>
                {hours.map((hour) => (
                    <ListItem
                        sx={{
                            padding: 0,
                            margin: 0,
                        }}
                        key={hour.id}>
                        <ListItemText
                            primary={`${convertDayOfWeekToString(hour.dayOfWeek)}`}
                            primaryTypographyProps={{
                                variant: 'body2',
                                color: hour.dayOfWeek === today ? 'primary' : 'text.primary',
                            }}
                            secondary={`${convertTimeStampTo12HourTime(hour.openTime)} - ${convertTimeStampTo12HourTime(hour.closeTime)}`}
                            secondaryTypographyProps={{
                                variant: 'subtitle1',
                                color: 'text.primary',
                            }}
                        />
                    </ListItem>
                ))}
            </List>
        );
    };

    return (
        <>
            {isAnyDayOpen && (
                <Button
                    size='small'
                    color={
                        openNow ? (closingSoon ? 'warning' : 'success') : openSoon ? 'warning' : 'error'
                    }
                    variant='outlined'
                    onClick={handlePopoverOpen}
                >
                    <Typography variant="body2">
                        <Box
                            component={'span'}
                            color={
                                openNow ? (closingSoon ? 'warning.main' : 'success.main') : openSoon ? 'warning.main' : 'error.main'
                            }
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}
                        >
                            {openNow
                                ? (closingSoon ? `Closing in ${getMinutesUntilFormatted(currentDayHoursOfOperation?.closeTime)}` : 'Open Now')
                                : openSoon
                                    ? `Opening in ${getMinutesUntilFormatted(currentDayHoursOfOperation?.openTime)}`
                                    : 'Closed'}
                        </Box>
                    </Typography>
                </Button>
            )}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ p: 2 }}>
                    {renderHoursOfOperation(restaurant.hoursOfOperation)}
                </Box>
            </Popover>
        </>
    );
};
