import { Autocomplete, Box, Chip, SxProps, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React, { FC } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { httpClient } from "../../Interceptors/httpClient";
import { Error } from "../Error/Error";

export interface ZipCodeMultiSelectProps {
    disabled?: boolean;
    name: string;
    value: any;
    onChange: (value: any) => void;
    sx?: SxProps;
}

export const ZipCodeMultiSelect: FC<ZipCodeMultiSelectProps> = (props) => {
    const { disabled, name, value, onChange, sx } = props;

    const { data, isLoading, isFetching, error } = useQuery<string[], AxiosError>({
        queryKey: ["zip-codes"],
        queryFn: async () => {
            const response = await httpClient.get<any[]>(`/ZipCodes`);
            return response?.data.map((zip) => zip);
        },
        refetchOnWindowFocus: false,
    });

    const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
        function ListboxComponent(props, ref) {
            const { children, ...other } = props;
            const itemData = React.Children.toArray(children);
            const itemCount = itemData.length;
            const itemSize = 36;

            const maxListHeight = 200; // Set the maximum height of the list in pixels

            return (
                <div ref={ref} {...other}>
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <FixedSizeList
                                height={Math.min(itemCount * itemSize, maxListHeight)}
                                width={width ?? 0}
                                itemData={itemData}
                                itemCount={itemCount}
                                itemSize={itemSize}
                                overscanCount={5}
                            >
                                {({ index, style }) => (
                                    <div style={style}>
                                        {itemData[index]}
                                    </div>
                                )}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </div>
            );
        }
    );

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Autocomplete
                disabled={disabled}
                multiple
                id={name}
                options={data ?? []}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                value={value}
                ListboxComponent={ListboxComponent}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Zip Codes"
                        placeholder="Zip Codes"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading || isFetching ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
                onChange={(event, newValue) => {
                    onChange(newValue);
                }}
                loading={true}
                sx={sx}
                loadingText={error?.message ?? "Loading..."}
            />
            {error && <Error error={error ?? "An unknown error occurred."} />}
        </Box>
    );
};

