import { Clear, Search } from '@mui/icons-material';
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
import { useState } from 'react';

interface EnhancedTableToolbarProps {
    onSearchChange(value: string): void;
    isLoading: boolean;
}

export const UserAdminTableToolbar = (props: EnhancedTableToolbarProps) => {
    const [search, setSearch] = useState('');

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        // Output to parent component
        props.onSearchChange(event.target.value);
    }

    const onSearchClear = () => {
        setSearch('');
        // Output to parent component
        props.onSearchChange('');
    }

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 }
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Users
            </Typography>
            <TextField
                sx={{ pl: 2 }}
                value={search}
                onChange={onSearchChange}
                variant="outlined"
                placeholder="Search email"
                size="small"
                autoFocus={true}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {(props.isLoading && <CircularProgress size={20} />)}
                            {(search.length > 0 && !props.isLoading) &&
                                <Tooltip title="Clear search">
                                    <Clear onClick={onSearchClear}
                                        style={{ cursor: 'pointer' }} />
                                </Tooltip>
                            }
                            {(search.length === 0 && !props.isLoading) &&
                                <Search />
                            }
                        </InputAdornment>
                    ),
                }}
            >
            </TextField>
        </Toolbar>
    );
};
