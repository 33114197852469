import { Box, Button, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { Error } from "../../Components/Error/Error";
import { httpClient } from "../../Interceptors/httpClient";

export const ResetPassword: FC<{}> = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    const [error, setError] = useState<AxiosError | string>('');
    const [passwordChanged, setPasswordChanged] = useState(false);
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, "Password must be at least 8 characters")
            .required("Password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Confirm password is required"),
    });

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setError('');
            if (!token) {
                setError("Invalid token");
                return;
            }
            try {
                const response = await httpClient.post('/identity/resetPassword', {
                    newPassword: values.password,
                    token: token,
                    email: email,
                });
                if (response.status === 200) {
                    setPasswordChanged(true);
                }
            }
            catch (e: any) {
                setError(e ?? 'An unknown error occurred while resetting password.');
            }
        },
    });

    return (
        token ? (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%"
                }}>
                {!passwordChanged ? (
                    <Box sx={{
                        maxWidth: '250px'
                    }}>
                        <Typography sx={{ textAlign: 'center' }} variant="h4">Reset Password</Typography>
                        <Box
                            component="form"
                            sx={{
                                display: "flex",
                                flexDirection: "column"
                            }}
                            onSubmit={formik.handleSubmit}>
                            <TextField
                                label="Password"
                                name="password"
                                type="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                disabled={formik.isSubmitting}
                                error={!!formik.errors.password && formik.touched.password}
                                helperText={!!formik.errors.password && formik.touched.password ? formik.errors.password : ""}
                                variant="filled"
                                margin="normal"
                            />
                            <TextField
                                label="Confirm Password"
                                name="confirmPassword"
                                type="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmPassword}
                                disabled={formik.isSubmitting}
                                error={!!formik.errors.confirmPassword && formik.touched.confirmPassword}
                                helperText={!!formik.errors.confirmPassword && formik.touched.confirmPassword ? formik.errors.confirmPassword : ""}
                                variant="filled"
                                margin="normal"
                            />
                            <Error error={error} />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "1rem"
                                }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    title="Reset Password"
                                    color="primary" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                                >
                                    {formik.isSubmitting ? "Resetting..." : "Reset Password"}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box>
                        <Typography sx={{ textAlign: 'center' }} variant="h4">Password reset successful</Typography>
                        <Typography sx={{ textAlign: 'center' }} variant="h5">You can now login with your new password</Typography>
                        <Box
                            sx={{
                                textAlign: 'center',
                                marginTop: '1rem'
                            }}
                        >
                            <Button variant="contained" color="primary" onClick={() => navigate("/login")}>
                                Login
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        ) : (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%"
                }}
            >
                <Typography
                    sx={{
                        mb: 2,
                    }}
                    color="error"
                    variant="h4">
                    Invalid Token
                </Typography>
                <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                    Home
                </Button>
            </Box>
        )
    );
}