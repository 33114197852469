import axios from "axios";

export const httpClient = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
  },
});

// Use the interceptor to add the Bearer token to each request
httpClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: any | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Use response interceptor to automatically refresh tokens if needed and retry original requests
httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, response: { status } = { status: 0 } } = error;
    const originalRequest = config;

    // If the request failed due to unauthorized access and it's not already a retry for token refresh
    if (status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem("refreshToken");
        return new Promise((resolve, reject) => {
          httpClient
            .post("/identity/refresh", { refreshToken })
            .then(({ data }) => {
              localStorage.setItem("token", data.token);
              localStorage.setItem("refreshToken", data.refreshToken);
              httpClient.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${data.token}`;
              originalRequest.headers["Authorization"] = `Bearer ${data.token}`;
              processQueue(null, data.token);
              resolve(httpClient(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }
      // If a token refresh is already in progress, queue this request and resolve/reject accordingly
      return new Promise((resolve, reject) => {
        failedQueue.push({
          resolve: (token: any) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            resolve(httpClient(originalRequest));
          },
          reject: (err: any) => {
            reject(err);
          },
        });
      });
    }

    return Promise.reject(error);
  }
);
