export function convertTimeStampTo12HourTime(utcTimestamp: string): string {
  try {
    // Normalize time to HH:MM:SS format
    utcTimestamp = normalizeTimeFormat(utcTimestamp);
  } catch (error) {
    return ""; // Return empty if normalization fails
  }

  // Handle special case for 24:00:00 (midnight)
  if (utcTimestamp === "24:00:00") {
    utcTimestamp = "00:00:00"; // Treat 24:00:00 as 00:00:00 (midnight)
  }

  // Validate input format
  if (!/^\d{2}:\d{2}:\d{2}$/.test(utcTimestamp)) {
    return "";
  }

  // Extract hours, minutes, and seconds
  const [hours, minutes, seconds] = utcTimestamp.split(":").map(Number);

  // Validate hours, minutes, and seconds
  if (
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59 ||
    seconds < 0 ||
    seconds > 59
  ) {
    return ""; // Return empty for invalid time values
  }

  // Convert to a local date using the provided UTC time
  const now = new Date();
  const localDate = new Date(
    Date.UTC(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours,
      minutes,
      seconds
    )
  );

  // Extract local hours and minutes
  const localHours = localDate.getHours();
  const localMinutes = localDate.getMinutes();

  // Convert to 12-hour format
  const period = localHours >= 12 ? "PM" : "AM";
  let hour12 = localHours % 12 || 12; // Convert 0 hours to 12 for 12 AM

  // Format the time with leading zeros
  return `${hour12.toString().padStart(2, "0")}:${localMinutes
    .toString()
    .padStart(2, "0")} ${period}`;
}

export function convert12HourTimeToUtcTimeStamp(
  localTimestamp: string
): string {
  // Validate input format
  if (!/^\d{2}:\d{2} (AM|PM)$/.test(localTimestamp)) {
    return "";
  }

  // Extract hours, minutes, and period
  const [time, period] = localTimestamp.split(" ");
  const [hours, minutes] = time.split(":").map(Number);

  // Validate hours, minutes, and period
  if (
    hours < 1 ||
    hours > 12 ||
    minutes < 0 ||
    minutes > 59 ||
    !["AM", "PM"].includes(period)
  ) {
    return "";
  }

  // Convert to 24-hour local time format
  let localHours = hours;

  if (period === "PM" && hours !== 12) {
    localHours += 12;
  } else if (period === "AM" && hours === 12) {
    localHours = 0;
  }

  // Create a Date object with the current date and the provided local time
  const now = new Date();
  const localDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    localHours,
    minutes
  );

  // Convert the local time to UTC
  const utcHours = localDate.getUTCHours();
  const utcMinutes = localDate.getUTCMinutes();

  // Format and return the result
  return `${utcHours.toString().padStart(2, "0")}:${utcMinutes
    .toString()
    .padStart(2, "0")}:00`;
}

// Normalize time to HH:MM:SS format by converting days to hours if present
export function normalizeTimeFormat(time: string): string {
  // Replace any periods with colons
  time = time.replace(/\./g, ":");

  const dayHourMinuteSecondFormat = /^(\d+):([0-5]\d):([0-5]\d):([0-5]\d)$/; // Match format with days
  const hourMinuteSecondFormat = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/; // Match HH:MM:SS format

  if (dayHourMinuteSecondFormat.test(time)) {
    const [days, hours, minutes, seconds] = time.split(":").map(Number);
    const totalHours = days * 24 + hours;

    return `${String(totalHours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  }
  if (hourMinuteSecondFormat.test(time)) {
    // Already in correct format, return as is
    return time;
  }
  throw new Error(`Invalid time format: ${time}`);
}

export function isCurrentTimeBetween(
  startTime: string,
  endTime: string
): boolean {
  try {
    // Normalize start and end times to HH:MM:SS format, handling 24:00:00 case
    startTime = normalizeTimeFormat(startTime);
    endTime = normalizeTimeFormat(endTime);

    // Handle the special case where endTime is "24:00:00", treat it as "00:00:00" of the next day
    if (endTime === "24:00:00") {
      endTime = "00:00:00";
    }
  } catch (error) {
    console.error(error);
    return false;
  }

  // Validate input format (HH:MM:SS)
  const timeFormat = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
  if (!timeFormat.test(startTime) || !timeFormat.test(endTime)) {
    return false;
  }

  // Parse start and end times into hours, minutes, and seconds
  const [startHour, startMinute, startSecond] = startTime
    .split(":")
    .map(Number);
  const [endHour, endMinute, endSecond] = endTime.split(":").map(Number);

  // Convert times to seconds since the start of the day
  const startInSeconds = startHour * 3600 + startMinute * 60 + startSecond;
  const endInSeconds =
    endHour * 3600 +
    endMinute * 60 +
    endSecond +
    (endTime === "00:00:00" ? 86400 : 0); // Add 24 hours in seconds if it's the next day (midnight)

  // Get current local time
  const now = new Date();

  // Convert current local time to UTC
  const currentUTCInSeconds =
    now.getUTCHours() * 3600 + now.getUTCMinutes() * 60 + now.getUTCSeconds();

  // Check if the time crosses midnight
  if (endInSeconds < startInSeconds) {
    // Time crosses midnight, so the current time is between start and end if:
    // (1) current is greater than or equal to start, or
    // (2) current is less than or equal to end
    return (
      currentUTCInSeconds >= startInSeconds ||
      currentUTCInSeconds <= endInSeconds
    );
  }

  // Standard case: time does not cross midnight
  return (
    currentUTCInSeconds >= startInSeconds && currentUTCInSeconds <= endInSeconds
  );
}

export function getDateFromUtcTimeStamp(utcTimestamp: string): Date {
  try {
    // Normalize time to HH:MM:SS format
    utcTimestamp = normalizeTimeFormat(utcTimestamp);
  } catch (error) {
    return new Date(); // Return current date if normalization fails
  }

  // Handle special case for 24:00:00 (midnight)
  if (utcTimestamp === "24:00:00") {
    utcTimestamp = "00:00:00"; // Treat 24:00:00 as 00:00:00 (midnight)
  }

  // Validate input format
  if (!/^\d{2}:\d{2}:\d{2}$/.test(utcTimestamp)) {
    return new Date(); // Return current date if the format is invalid
  }

  // Extract hours, minutes, and seconds
  const [hours, minutes, seconds] = utcTimestamp.split(":").map(Number);

  // Validate hours, minutes, and seconds
  if (
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59 ||
    seconds < 0 ||
    seconds > 59
  ) {
    return new Date();
  }

  // Create a Date object with the current date and the provided UTC time
  const now = new Date();

  const finalDate = new Date(
    Date.UTC(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours,
      minutes,
      seconds
    )
  );

  if (utcTimestamp === "00:00:00") {
    finalDate.setUTCDate(finalDate.getUTCDate() + 1);
  }

  return finalDate;
}

export function isCurrentTimeLessThanOneHourAwayFrom(date: Date): boolean {
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  return diff > 0 && diff <= 3600000;
}

export function getMinutesUntilFormatted(
  utcTimestamp: string | undefined
): string {
  if (!utcTimestamp) {
    return "";
  }

  const date: Date = getDateFromUtcTimeStamp(utcTimestamp);
  const now: Date = new Date();

  const dateTimestamp: number = date.getTime();
  const nowTimestamp: number = now.getTime();

  const diff: number = dateTimestamp - nowTimestamp;

  const minutes: number = Math.floor(diff / 60000);

  if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""}`;
  } else if (diff > 0) {
    return "1 minute";
  } else {
    return "";
  }
}

export function isClosingTimeLessThanOneHourAway(
  utcTimestamp: string | undefined
): boolean {
  if (!utcTimestamp) {
    return false;
  }

  const closingTime = getDateFromUtcTimeStamp(utcTimestamp);
  const now = new Date();

  // Subtract the both stored time value in milliseconds since midnight, January 1, 1970 UTC.
  const diff = closingTime.getTime() - now.getTime();

  // Convert the difference to minutes
  const minutes = diff / 60000;

  // Return true if the closing time is less than one hour away
  return minutes > 0 && minutes <= 60;
}
