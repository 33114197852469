import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SxProps, useMediaQuery} from "@mui/material";
import React, {useEffect} from "react";

// Should be able to pass in a disabled flag and have the dropdown be disabled
export interface ITimeSlotDropDownProps {
    selectedTimeSlot?: string;
    disabled?: boolean;
    onTimeSlotSelected?: (timeSlot: string) => void;
    size?: "small" | "medium";
    sx?: SxProps;
    label?: string;
}

export const TimeSlotDropDown = (props: ITimeSlotDropDownProps) => {
    const {selectedTimeSlot, disabled, onTimeSlotSelected} = props;
    const [timeSlot, setTimeSlot] = React.useState<string | undefined>(selectedTimeSlot || '12:00 AM');
    const {sx} = props;

    const handleChange = (event: SelectChangeEvent) => {
        setTimeSlot(event.target.value as string);
        onTimeSlotSelected?.(event.target.value as string);
    };

    const isSmallScreen = useMediaQuery('(max-width:768px)');

    const generateTimeSlots = () => {
        const timeSlots: string[] = [];
        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 60; j += 15) {
                const hour = i === 0 ? 12 : i > 12 ? i - 12 : i;
                const minute = j === 0 ? '00' : j;
                const amPm = i < 12 ? 'AM' : 'PM';
                // If hour is single digit, add a leading zero
                if (hour < 10) {
                    timeSlots.push(`0${hour}:${minute} ${amPm}`);
                } else {
                    timeSlots.push(`${hour}:${minute} ${amPm}`);
                }
            }
        }
        return timeSlots;
    };

    // When selectedTimeSlot changes, update the timeSlot state
    useEffect(() => {
        setTimeSlot(selectedTimeSlot);
    }, [selectedTimeSlot]);

    return (
        <FormControl fullWidth size={props.size || 'small'}>
            <InputLabel>
                {props.label || 'Select a time'}
            </InputLabel>
            <Select
                sx={{
                    width: isSmallScreen ? '100%' : 250,
                    ...sx
                }}
                value={timeSlot}
                onChange={handleChange}
                label={props.label || 'Select a time'}
                size={props.size || 'small'}
                disabled={disabled}
                // Change the height of the dropdown
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: 200,
                        },
                    },
                }}
            >
                {generateTimeSlots().map((timeSlot) => (
                    <MenuItem
                        key={timeSlot}
                        value={timeSlot}>{timeSlot}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};