import Menu from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Drawer, IconButton, Paper, Typography, useMediaQuery } from "@mui/material";
import { useLocalStorage } from '@rehooks/local-storage';
import { useEffect, useRef, useState } from 'react';
import { decodeToken } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import { Token } from '../../Models/Auth/token.model';
import { Main } from '../Main/Main';
import { SideNavMenu } from '../SideNavMenu/SideNavMenu';

export const ContentsWrapper: React.FC = () => {
    const [token] = useLocalStorage('token');
    const decodedToken = decodeToken<Token>(token || '');
    const [open] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const navigate = useNavigate();
    const isLessThan500Pixels = useMediaQuery('(max-width:500px)');
    const is1024OrGreater = useMediaQuery('(min-width:1024px)');
    const [sideMenuOpenDesktop, setSideMenuOpenDesktop] = useState(decodedToken ? true : false);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleDrawerToggle = (closeDesktop?: boolean) => {
        const shouldCloseDesktop = closeDesktop ?? false;
        setSideMenuOpenDesktop(!shouldCloseDesktop);
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <Box sx={{ display: 'flex', height: '100%', overflow: 'auto' }}>
            {is1024OrGreater ?
                <Box
                    sx={{
                        width: (theme) => sideMenuOpenDesktop ? 350 : 0,
                    }}
                >
                    <Drawer
                        variant="persistent"
                        open={sideMenuOpenDesktop}
                        sx={{
                            width: 350,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: 350,
                                boxSizing: 'border-box'
                            }
                        }}>
                        <SideNavMenu handleToggleClose={handleDrawerToggle} />
                    </Drawer>
                </Box>
                : null}
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'auto' }}>
                <AppBar
                    position="static"
                    sx={{
                        zIndex: 2,
                        boxShadow: (theme) => theme.shadows[0],
                    }}
                >
                    <Paper sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 2,
                        paddingRight: 2,
                        paddingTop: .3,
                        paddingBottom: .3,
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                        backgroundColor: (theme) => theme.palette.background.paper,
                        borderRadius: 0
                    }}
                        component={'header'}
                    >
                        {(!is1024OrGreater || !sideMenuOpenDesktop) && (
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => {
                                    setIsMenuOpen(true);
                                    setSideMenuOpenDesktop(true);
                                }}
                            >
                                <Menu />
                            </IconButton>
                        )}
                        <Box sx={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "50%",
                            objectFit: "cover",
                            objectPosition: "center",
                            backgroundSize: "cover",
                            cursor: 'pointer'
                        }}
                            component={'img'}
                            onClick={() => navigate('/')}
                            src="/logo.png"
                            alt="Happy Hour Logo"
                        >
                        </Box>
                        {!isLessThan500Pixels && (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <Typography
                                    sx={{
                                        cursor: 'pointer',
                                        mb: 0
                                    }}
                                    variant="h6"
                                    component="div"
                                    onClick={() => navigate('/')}
                                >
                                    Happy Hour
                                </Typography>
                            </Box>
                        )}
                        <Box sx={{
                            flexGrow: 1
                        }}></Box>
                        {/* If decodedToken is null then show Sign Up and Login button */}
                        {!decodedToken ? (
                            <>
                                <Button
                                    sx={{ minWidth: isLessThan500Pixels ? "100px" : "120px" }}
                                    variant="outlined"
                                    color="inherit"
                                    onClick={() => navigate('/login')}
                                    size={isLessThan500Pixels ? "small" : "medium"}
                                >
                                    Sign In/Up
                                </Button>
                            </>
                        ) : (
                            <>
                            </>
                        )}
                    </Paper>
                    {!is1024OrGreater ? (
                        <Drawer
                            variant="temporary"
                            open={isMenuOpen}
                            onClose={() => setIsMenuOpen(false)}
                            ModalProps={{
                                keepMounted: true
                            }}
                        >
                            <SideNavMenu handleToggleClose={handleDrawerToggle} />
                        </Drawer>
                    ) : (
                        null
                    )}
                </AppBar>
                <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                    <Main />
                </Box>
            </Box>
        </Box>
    );
}