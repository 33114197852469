import { CloseOutlined, LocationOnOutlined, Search } from "@mui/icons-material";
import { Autocomplete, Box, FormGroup, IconButton, SxProps, TextField, Typography, useMediaQuery } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { httpClient } from "../../Interceptors/httpClient";

const currentLocation = "Current Location";

export interface HappyHourSearchProps {
    sx?: SxProps;
    onSearch: (search?: string, zipCode?: string) => void;
    location?: GeolocationCoordinates;
}

export const HappyHourSearch = (props: HappyHourSearchProps): JSX.Element => {
    const [searchParams] = useSearchParams();
    const { sx, onSearch, location } = props;
    const [inputValue, setInputValue] = useState('');
    const isSmallScreen = useMediaQuery('(max-width:400px)');

    const validationSchema = yup.object({
        search: yup
            .string()
            .max(50, "Search must be less than 50 characters"),
        zipCodes: yup
            .string()
            .matches(/(^\d{5}$|^$)/, "Zip code must be 5 digits"),
    });

    const formik = useFormik({
        initialValues: {
            search: searchParams.get("search") || "",
            zipCode: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const zipCode = (values.zipCode === "Current Location" ? "" : values.zipCode) || undefined;
            onSearch(values.search || undefined, zipCode);
        },
    });

    const { data: zipCodes, isLoading, refetch } = useQuery<string[], AxiosError>({
        queryKey: ["zipCodesSearch", inputValue],
        queryFn: async () => {
            if (!inputValue) {
                return [];
            }

            const response = await httpClient.get<string[]>(`/zipCodes/search/${encodeURIComponent(inputValue)}`);
            return response.data;
        },
        enabled: false,
    });

    useEffect(() => {
        if (inputValue) {
            refetch();
        }
    }, [inputValue, refetch]);

    useEffect(() => {
        if (location && !formik.values.zipCode) {
            formik.setFieldValue("zipCode", currentLocation);
        }
        if (!location && formik.values.zipCode === '') {
            formik.setFieldValue("zipCode", null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <Box component="form" onSubmit={formik.handleSubmit}>
            <FormGroup row sx={sx}>
                <TextField
                    id="happy-hour-search"
                    sx={{
                        width: isSmallScreen ? 190 : 245,
                        borderRadius: '8px 0 0 8px',
                    }}
                    size="small"
                    variant="outlined"
                    placeholder="Search"
                    autoFocus
                    value={formik.values.search}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="search"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            formik.handleSubmit();
                        }
                    }}
                    InputProps={{
                        startAdornment: <Search />,
                        endAdornment: formik.values.search && (
                            <IconButton
                                sx={{
                                    padding: 0.5,
                                }}
                                onClick={() => {
                                    formik.setFieldValue("search", "");
                                    const zipCode = (formik.values.zipCode === currentLocation ? "" : formik.values.zipCode) || undefined;
                                    onSearch(undefined, zipCode);
                                }}
                            >
                                <CloseOutlined fontSize="small" />
                            </IconButton>
                        ),
                        sx: {
                            borderRadius: '8px 0 0 8px',
                        },
                    }}
                />
                <Autocomplete
                    id="happy-hour-search-location"
                    sx={{
                        position: 'relative',
                        width: 155,
                        '& .MuiFormControl-root': {
                            borderRadius: '0 8px 8px 0'
                        },
                    }}
                    size="small"
                    options={zipCodes || []}
                    loading={isLoading}
                    loadingText={(<Typography
                        variant="body2"
                        sx={{
                            color: theme => theme.palette.grey[500],
                            textAlign: 'center',
                        }}
                    >
                        Searching...
                    </Typography>)}
                    inputValue={inputValue}
                    value={formik.values.zipCode}
                    filterOptions={(options, state) => {
                        if (location && options.length > 1) {
                            return [currentLocation, ...options];
                        }
                        return options;
                    }}
                    onKeyDown={(e) => {
                        // Only allow numbers and backspace
                        const isNumber = /^[0-9]$/;
                        if (!isNumber.test(e.key) && e.key !== 'Backspace') {
                            e.preventDefault();
                        }
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue ?? '');
                    }}
                    noOptionsText={formik.values.zipCode ?
                        <Typography
                            variant="body2"
                            sx={{
                                color: theme => theme.palette.grey[500],
                            }}
                        >
                            No zip codes found
                        </Typography> :
                        <>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: theme => theme.palette.grey[500],
                                }}
                            >
                                Zip code search
                            </Typography>
                        </>
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderLeft: 'none',
                                        borderRadius: '0 8px 8px 0',
                                    },
                                },
                            }}
                            variant="outlined"
                            placeholder="By Location"
                            onBlur={(e) => {
                                if (!formik.values.zipCode && location) {
                                    formik.setFieldValue("zipCode", currentLocation);
                                }
                                formik.handleBlur(e);
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        {formik.values.zipCode === currentLocation && (
                                            <LocationOnOutlined fontSize="small" color="success" />
                                        )}
                                        {params.InputProps.endAdornment}
                                    </Box>
                                ),
                            }}
                        />
                    )}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            {...props}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '8px 16px',
                                '&:hover': {
                                    backgroundColor: theme => theme.palette.grey[100],
                                },
                            }}
                        >
                            {/* If "Current Location option then render with Location Icon" */}
                            {option === currentLocation ?
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <Typography variant="body2">{option}</Typography>
                                    <LocationOnOutlined color="success" sx={{
                                        marginLeft: 1,
                                    }} />
                                </Box> :
                                <Typography variant="body2">{option}</Typography>
                            }
                        </Box>
                    )}
                    getOptionLabel={(option) => option === currentLocation ? "Current" : option}
                    onChange={(event, value) => {
                        const selectedValue = value === currentLocation ? '' : value;
                        formik.setFieldValue("zipCode", value);
                        onSearch(formik.values.search || undefined, selectedValue || undefined);
                    }}
                />
            </FormGroup>
        </Box>
    );
};
