import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { User } from "../../../Models/Auth/user.model";

export type Order = 'asc' | 'desc';
export type TableUser = Omit<User, 'phoneNumberConfirmed' | 'twoFactorEnabled' | 'profileImage' | 'profileImageFileName' | 'tokens'>;

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableUser) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof TableUser;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Id',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'firstName',
        numeric: false,
        disablePadding: false,
        label: 'First Name',
    },
    {
        id: 'lastName',
        numeric: false,
        disablePadding: false,
        label: 'Last Name',
    },
    {
        id: 'lockoutEnabled',
        numeric: false,
        disablePadding: false,
        label: 'Lockout Enabled',
    },
    {
        id: 'roles',
        numeric: false,
        disablePadding: false,
        label: 'Roles',
    },
];

export const UserAdminTableHead = (props: EnhancedTableProps) => {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof TableUser) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
