import { Box, Container, Grid, Typography } from "@mui/material";

export const About = (): JSX.Element => {
    return (
        <Container maxWidth="md">
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={10} md={8}>
                    <Box m={2}>
                        <Typography variant="h3">Happy Hour Discovery</Typography>

                        <Typography variant="body1" paragraph>
                            At <strong>Happy Hour Discovery</strong>, our mission is to make sure that your next happy hour is not just good, but exceptional. We are a dedicated platform that connects you to the best restaurant and bar happy hour deals in your local area.
                        </Typography>

                        <Typography variant="h4">Discover Local Restaurants and Bars</Typography>

                        <Typography variant="body1" paragraph>
                            With an extensive list of establishments, we take pride in being the go-to resource for those in search of the best happy hour deals in your area. From cozy neighborhood bars to elegant restaurants, we help you find the perfect place to unwind after a long day.
                        </Typography>

                        <Typography variant="h4">Find the Best Deals</Typography>

                        <Typography variant="body1" paragraph>
                            We know that a great deal can make happy hour even happier. That's why we meticulously gather and update information about the best discounts and deals available during happy hours in area.
                        </Typography>

                        <Typography variant="h4">Save Time and Money</Typography>

                        <Typography variant="body1" paragraph>
                            Instead of wasting time searching for happy hour deals or ending up at a place that doesn't meet your expectations, use Happy Hour Discovery. We make it easy to find the perfect spot with the best deals, saving you time and money.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Enjoy the best that your area's local restaurants and bars have to offer with Happy Hour Discovery - your partner for a truly happy hour!
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}