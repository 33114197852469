import { ChevronRight } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from "@mui/icons-material/Search";
import { Box, Divider, IconButton, InputBase, List, ListItem, ListItemButton, ListItemText, ListSubheader } from "@mui/material";
import { useEffect, useState } from "react";
import { Restaurant } from "../../../Models/Restaurant/Restaurant";
import { RestaurantsActionSplitButton, restaurantAdminListActions } from "./RestaurantsActionSplitButton";
export interface RestaurantAdminListProps {
    disabled?: boolean;
    restaurants: Restaurant[];
    isSmallScreen: boolean;
    onRestaurantSelect: (restaurant: Restaurant | undefined) => void;
    onSearch: (searchTerm: string, addressSearchTerm?: string) => void;
    onAction: (action: typeof restaurantAdminListActions[number]) => void;
    selectedRestaurantId?: string | undefined;
}
export const RestaurantAdminList = (props: RestaurantAdminListProps) => {
    const { restaurants, isSmallScreen, onRestaurantSelect, onSearch, onAction, selectedRestaurantId, disabled } = props;
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
    const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
    const [showAddressSearch, setShowAddressSearch] = useState(false);
    const [addressSearchTerm, setAddressSearchTerm] = useState("");

    useEffect(() => {
        const restaurant = restaurants.find((restaurant) => restaurant.id === selectedRestaurantId);
        setSelectedId(restaurant?.id);
    }, [selectedRestaurantId, restaurants]);

    return (
        <List
            sx={{
                width: isSmallScreen ? "100%" : 360,
                height: "calc(100% - 32px)",
                overflowY: "auto",
            }}
            subheader={
                <>
                    <ListSubheader sx={
                        {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "8px"
                        }
                    } component="div">
                        {/* Add a refresh icon next to restaurants title */}
                        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <span>
                                Restaurants
                            </span>
                            <IconButton
                                onClick={() => {
                                    onAction("Refresh");
                                }}
                                disabled={disabled}
                            >
                                <RefreshIcon fontSize="small" />
                            </IconButton>
                        </Box>
                        <RestaurantsActionSplitButton disabled={disabled}
                            onAction={
                                (action) => {
                                    onAction(action);
                                }
                            }></RestaurantsActionSplitButton>
                    </ListSubheader>
                    <Divider />
                    <Box sx={{ display: "flex", width: "100%" }}>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                            "&:hover": {
                                cursor: "pointer",
                                backgroundColor: (theme) => theme.palette.action.hover,
                            },
                        }}
                            onClick={() => {
                                setShowAddressSearch(!showAddressSearch);
                            }}
                        >
                            <ChevronRight />
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "2", p: "2" }}>
                                <SearchIcon />
                                <InputBase
                                    sx={{ ml: 1, flex: 1, boxShadow: "none" }}
                                    value={searchTerm}
                                    placeholder="Search Name"
                                    inputProps={{ "aria-label": "search" }}
                                    onChange={(event) => {
                                        setSearchTerm(event.target.value);
                                        // submit search term after 500ms
                                        clearTimeout(searchTimeout);
                                        setSearchTimeout(setTimeout(() => {
                                            onSearch(event.target.value, addressSearchTerm);
                                        }
                                            , 500));
                                    }}
                                />
                                {searchTerm && <IconButton
                                    onClick={() => {
                                        setSearchTerm("");
                                        // submit search term after 500ms
                                        clearTimeout(searchTimeout);
                                        setSearchTimeout(setTimeout(() => {
                                            onSearch("", addressSearchTerm);
                                        }
                                            , 500));
                                    }}
                                    size="small"
                                    sx={{ p: "0" }}
                                >
                                    {/* Clear search on icon press */}
                                    <ClearIcon />
                                </IconButton>}
                            </Box>
                            {/* If address search is shown then add divider */}
                            {showAddressSearch && <Divider />}
                            {showAddressSearch && <Box
                                sx={{ display: "flex", alignItems: "center", gap: "2", p: "2" }}>
                                <SearchIcon />
                                <InputBase
                                    sx={{ ml: 1, flex: 1, boxShadow: "none" }}
                                    value={addressSearchTerm}
                                    placeholder="Search Address"
                                    inputProps={{ "aria-label": "search" }}
                                    onChange={(event) => {
                                        setAddressSearchTerm(event.target.value);
                                        // submit search term after 500ms
                                        clearTimeout(searchTimeout);
                                        setSearchTimeout(setTimeout(() => {
                                            onSearch(searchTerm, event.target.value);
                                        }
                                            , 500));
                                    }}
                                />
                                {addressSearchTerm && <IconButton
                                    onClick={() => {
                                        setAddressSearchTerm("");
                                        // submit search term after 500ms
                                        clearTimeout(searchTimeout);
                                        setSearchTimeout(setTimeout(() => {
                                            onSearch(searchTerm, "");
                                        }
                                            , 500));
                                    }}
                                    size="small"
                                    sx={{ p: "0" }}
                                >
                                    {/* Clear search on icon press */}
                                    <ClearIcon />
                                </IconButton>}
                            </Box>}
                        </Box>
                    </Box>
                    <Divider />
                </>
            }
        >
            {/* If no restaurants exists show message and center it */}
            {restaurants.length === 0 &&
                <ListItem>
                    <ListItemText sx={{ textAlign: "center" }}
                        primary="No restaurants found"
                    />
                </ListItem>}
            {restaurants.map((restaurant) => (
                <div key={restaurant.id}>
                    <ListItemButton
                        disabled={disabled}
                        selected={selectedId === restaurant.id}
                        onClick={() => {
                            onRestaurantSelect(restaurant);
                            setSelectedId(restaurant.id);
                        }}
                    >
                        <ListItemText
                            primary={restaurant.name}
                            // Use complete address as secondary text
                            secondary={`${restaurant.address}, ${restaurant.address2 ? restaurant.address2 + ", " : ""
                                } ${restaurant.city}, ${restaurant.state} ${restaurant.zip}`}
                        />
                    </ListItemButton>
                    <Divider />
                </div>
            ))}
        </List>
    );
};