import { Autocomplete, CircularProgress, SxProps, TextField, useMediaQuery } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { LocationContext } from "../../Contexts/UserLocationContext";
import { httpClient } from "../../Interceptors/httpClient";
import { Restaurant } from "../../Models/Restaurant/Restaurant";

interface RestaurantAutocompleteProps {
    onSelect?: (restaurant: Restaurant) => void;
    bypassLocation?: boolean;
    sx?: SxProps;
    id?: string;
    placeholder?: string;
}

export interface RestaurantAutocompleteRef {
    clear: () => void;
}

export const RestaurantAutocomplete = forwardRef<RestaurantAutocompleteRef, RestaurantAutocompleteProps>((props, ref) => {
    const { onSelect } = props;
    const { sx } = props;

    const [value, setValue] = useState<Restaurant | null>(null);
    const [inputValue, setInputValue] = useState('');
    const timeout = useRef<NodeJS.Timeout>();
    const { location } = useContext(LocationContext);

    const isSmallScreen = useMediaQuery('(max-width:768px)');

    useImperativeHandle(ref, () => ({
        clear: () => {
            setValue(null);
            setInputValue('');
        }
    }));

    const { data: restaurants, isLoading, isFetching, refetch } = useQuery<Restaurant[], AxiosError>({
        queryKey: ["restaurantsSearch", inputValue],
        queryFn: async () => {
            if (!inputValue) {
                return [];
            }
            if (value) {
                return [value];
            }
            const encodedInput = encodeURIComponent(inputValue);
            if (location && !props.bypassLocation) {
                const radius = 20 * 1609.34;
                const response = await httpClient.get<Restaurant[]>(
                    `/restaurant/search/${encodedInput}/${location.latitude}/${location.longitude}/${radius}`
                );
                return response.data;
            }

            const response = await httpClient.get<Restaurant[]>(
                `/restaurant/search/${encodedInput}`
            );
            return response.data;
        },
        enabled: false,
    });

    // When the user selects a restaurant, call the onSelect prop
    // and set the value to the selected restaurant
    const handleChange = (event: React.ChangeEvent<{}>, newValue: Restaurant | null) => {
        setValue(newValue);
        if (newValue) {
            onSelect?.(newValue);
            setInputValue('');
            setValue(null);
        }
    }

    // When the user types in the input, set the inputValue to the value
    // and refetch the restaurants
    const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
        setInputValue(newInputValue);
    }

    // On input change, refetch the restaurants, should debounce this
    useEffect(() => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
            refetch();
        }, 300);
    }, [inputValue, refetch]);

    return (
        (<Autocomplete
            sx={{
                width: isSmallScreen ? '100%' : 250,
                ...sx
            }}
            id={props.id}
            loading={isLoading || isFetching}
            value={value}
            size="small"
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={restaurants || []}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (<li {...props} key={option.id}>
                {option.name}
            </li>)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={
                        isSmallScreen ?
                            props.placeholder ?
                                props.placeholder :
                                "Search" :
                            props.placeholder ?
                                props.placeholder :
                                "Search for a restaurant"
                    }
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {(isLoading || isFetching) ? <CircularProgress color="inherit" size={20} /> : null}
                                {/* Hide the arrow when isLoading or isFetching */}
                                {(!isLoading && !isFetching) ? params.InputProps.endAdornment : null}
                            </React.Fragment>
                        ),
                    }}
                >
                </TextField>
            )}
            noOptionsText={inputValue ? "No restaurants found" : "Start typing to search"}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />)
    );
});
