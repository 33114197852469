import { createContext, useEffect, useState } from "react";

export const LocationContext = createContext({
    location: null as GeolocationCoordinates | null,
    error: null as string | null,
    loading: true,
    setLocation: (location: GeolocationCoordinates | null) => { },
    setError: (error: string | null) => { },
    setLoading: (loading: boolean) => { },
});

export const LocationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [location, setLocation] = useState<GeolocationCoordinates | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation(position.coords);
                    setLoading(false);
                },
                (error) => {
                    setError(error.message);
                    setLoading(false);
                }
            );
        } else {
            setError("Geolocation is not supported by this browser.");
            setLoading(false);
        }
    }, []);

    return (
        <LocationContext.Provider value={{ location, error, loading, setLocation, setError, setLoading }}>
            {children}
        </LocationContext.Provider>
    );
};

