
export const handleKeyDownForNumbersOnly = (event: React.KeyboardEvent) => {
    // Check if key is a number, backspace, tab, left and right arrows keys, if not prevent default
    if (event.key !== "Backspace" &&
        event.key !== "Tab" &&
        !event.key.match(/^[0-9]+$/) &&
        event.key !== "ArrowLeft" &&
        event.key !== "ArrowRight" &&
        event.key !== "Delete" &&
        event.key !== "Enter" &&
        event.key !== "Escape") {
        event.preventDefault();
    }
};
