import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { Profile } from '../../Pages/Profile/Profile';
import { ZoomTransition } from "../RestaurantDialog/ZoomTransition";
import { ProfileDialogProps } from './ProfileDialogProps';

export const ProfileDialog = ({ open, handleClose }: ProfileDialogProps): JSX.Element => {

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            TransitionComponent={ZoomTransition}
        >
            <DialogTitle>
                Profile
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Profile handleClose={handleClose} />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="inherit"
                    variant="outlined"
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
