import { AccountCircle } from "@mui/icons-material";
import { Box, Button, CircularProgress, FormGroup, Tab, Tabs, TextField, useMediaQuery } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import { Error } from "../../Components/Error/Error";
import { UserContext } from "../../Contexts/UserContext";
import { httpClient } from "../../Interceptors/httpClient";
import { User } from "../../Models/Auth/user.model";
import { ProfileProps } from "./ProfileProps";

export const Profile = ({ handleClose }: ProfileProps): JSX.Element => {
    const { user, setUser } = useContext(UserContext);
    const [selectedProfileImage, setSelectedProfileImage] = useState<string | undefined>(undefined);
    const [error, setError] = useState<AxiosError | undefined | string>(undefined);
    const [tabValue, setTabValue] = useState<number>(0);
    const isMobile = useMediaQuery('(max-width:600px)');

    const formik = useFormik({
        initialValues: {
            email: user?.email || "",
            firstName: user?.firstName || "",
            lastName: user?.lastName || "",
            profileImage: user?.profileImage || "",
            fileName: user?.profileImageFileName || "",
        },
        onSubmit: async (values) => {
            setError(undefined);
            try {
                // Submit the form to the server
                const { data } = await httpClient.post<User>("/identity/me", {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    image: values.profileImage,
                    fileName: values.fileName,
                })
                setUser(data);
            } catch (error: any) {
                setError(error ?? 'Unknown error');
            }
        },
        enableReinitialize: true,
    });

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        // Validate the file is an image
        if (!file?.type.startsWith('image/')) {
            setError('Invalid file type. Please select an image file.');
            return;
        }
        if (file) {
            // get bytes from file and convert them into base64
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const profileImageResult = reader.result as string;
                // Set the selected profile image
                setSelectedProfileImage(profileImageResult);
                formik.setFieldValue("profileImage", reader.result as string);
                formik.setFieldValue("fileName", file.name);
            }
        }
    }

    return (user ? (
        <Box sx={{
            display: 'flex',
            flexDirection: isMobile ? "column" : "row",
            minHeight: isMobile ? "600px" : "500px",
        }}>
            <Tabs
                sx={{
                    maxWidth: isMobile ? "100%" : "100px",
                    minWidth: isMobile ? "100%" : "100px",
                }}
                value={tabValue}
                orientation={isMobile ? "horizontal" : "vertical"}
                variant="scrollable"
                scrollButtons={isMobile ? true : "auto"}
                allowScrollButtonsMobile
                onChange={(event, newValue) => setTabValue(newValue)}
            >
                <Tab label={'Profile'} />
            </Tabs>
            {tabValue === 0 && (
                <Box component={"form"} sx={{
                    m: 1,
                }}
                    onSubmit={formik.handleSubmit}
                >
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <FormGroup sx={{
                            alignItems: 'center',
                            display: 'flex !important',
                            flexDirection: 'row',
                            mb: 2,
                        }}>
                            {(formik.values.profileImage || selectedProfileImage) && (
                                <img
                                    src={`${selectedProfileImage ?? formik.values.profileImage}`}
                                    alt="profile"
                                    style={{
                                        "height": "75px",
                                        "marginRight": "1rem",
                                        "borderRadius": "50%"
                                    }}
                                />
                            )}
                            {(!formik.values.profileImage && !selectedProfileImage) && (
                                <AccountCircle sx={{
                                    fontSize: 75,
                                    mr: 1,
                                }} />
                            )}
                            {!formik.values.profileImage && !selectedProfileImage && (
                                <Box sx={{
                                    mr: 1
                                }}>
                                    <input
                                        title={'Add Profile Image'}
                                        style={{ display: "none" }}
                                        id="add-button-file"
                                        onChange={handleFileChange}
                                        type="file"
                                        accept="image/*"
                                    />
                                    <label style={{}} htmlFor="add-button-file">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component="span"
                                        >
                                            Add
                                        </Button>
                                    </label>
                                </Box>
                            )}
                            {(formik.values.profileImage || selectedProfileImage) && (
                                <>
                                    <Box sx={{
                                        mr: 1
                                    }}>
                                        <input
                                            title={'Change Profile Image'}
                                            style={{ display: "none" }}
                                            id="change-button-file"
                                            onChange={handleFileChange}
                                            type="file"
                                            accept="image/*"
                                        />
                                        <label style={{}} htmlFor="change-button-file">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component="span"
                                            >
                                                Change
                                            </Button>
                                        </label>
                                    </Box>
                                    <Button
                                        sx={{
                                            mr: 1
                                        }}
                                        variant="outlined"
                                        color="error"
                                        onClick={() => {
                                            setSelectedProfileImage(undefined);
                                            formik.setFieldValue("profileImage", "");
                                            formik.setFieldValue("fileName", "");
                                        }}
                                    >
                                        Remove
                                    </Button>
                                </>
                            )}
                        </FormGroup>
                        <TextField
                            sx={{
                                mb: 2,
                            }}
                            label="Email"
                            name="email"
                            disabled={true}
                            value={formik.values.email}
                        />
                        <TextField
                            sx={{
                                mb: 2,
                            }}
                            label="First Name"
                            name="firstName"
                            onChange={formik.handleChange}
                            disabled={formik.isSubmitting}
                            value={formik.values.firstName}
                        />
                        <TextField
                            sx={{
                                mb: 2,
                            }}
                            label="Last Name"
                            name="lastName"
                            onChange={formik.handleChange}
                            disabled={formik.isSubmitting}
                            value={formik.values.lastName}
                        />
                        {error && <Error error={error} />}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
                        >
                            {formik.isSubmitting ? (
                                "Saving..."
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    ) :
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
            }}
        >
            <CircularProgress size={50} />
        </Box>);
}
