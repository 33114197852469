import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useIsAdmin } from '../Hooks/is-admin';

export interface ProtectedAdminRouteProps {
    redirectTo: string;
    children: any;
}

export const ProtectedAdminRoute: FC<ProtectedAdminRouteProps> = ({
    redirectTo = '/',
    children,
}) => {
    const isAdmin = useIsAdmin();

    if (isAdmin) {
        return children;
    }

    return <Navigate to={redirectTo} />;
};
