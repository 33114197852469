import {
    Button,
    FormControl,
    InputLabel,
    Link,
    MenuItem,
    Pagination,
    Select,
    Tab,
    Tabs,
    TextField,
    useMediaQuery
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Country, State } from 'country-state-city';
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { ConfirmDialog } from "../../../Components/ConfirmDialog/ConfirmDialog";
import { Error } from "../../../Components/Error/Error";
import { httpClient } from "../../../Interceptors/httpClient";
import { HoursOfOperation } from "../../../Models/Restaurant/HoursOfOperation";
import { Restaurant } from "../../../Models/Restaurant/Restaurant";
import { RestaurantsResponse } from "../../../Models/Restaurant/RestaurantsResponse";
import { convert12HourTimeToUtcTimeStamp, convertTimeStampTo12HourTime } from "../../../Utilities/TimeStampConverterUtility";
import { HoursOfOperationAccordion } from '../HoursOfOperation/HoursOfOperationAccordion';
import { CuisineTypeAdmin } from "./CuisineTypeAdmin";
import { handleKeyDownForNumbersOnly } from "./handleKeyDownForNumbersOnly";
import { RestaurantActionEllipsis } from "./RestaurantActionEllipsis";
import { RestaurantAdminList } from "./RestaurantAdminList";
import { RestaurantForm } from "./RestaurantForm";
import { ZipCodeFormDialog } from "./ZipCodeFormDialog";

export const restaurantValidation = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    // Make sure if phone is provided, it is a valid phone number that would be accepted anywhere in the world
    // Make sure zip is code is only a number, and is valid for any country
    zip: Yup.string().matches(
        /^\d{5}(?:[-\s]\d{4})?$/,
        "Zip code is not valid"
    ),
    // If website is provided, make sure it is a valid URL
    website: Yup.string().url("Website is not valid").nullable(),
    // Check for valid phone number anywhere in the world
    phone: Yup.string().matches(
        /^(\+?1\s?)?(\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/,
        "Phone number is not valid"
    ).nullable(),
    // Latitude and longitude are not required, but if they are provided, make sure they are valid
    latitude: Yup.number().min(-90).max(90).nullable(),
    longitude: Yup.number().min(-180).max(180).nullable()
});

export const RestaurantsAdmin: FC = () => {
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [updateError, setUpdateError] = useState<AxiosError | null>(null);
    const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
    const [isDeleting, setIsDeleting] = useState(false);
    const [newMode, setNewMode] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const countries = Country.getAllCountries();
    const [states, setStates] = useState(State.getStatesOfCountry('US') || []);
    const [zipCodeDialogOpen, setZipCodeDialogOpen] = useState(false);
    const [addressSearchTerm, setAddressSearchTerm] = useState("");
    const [tabValue, setTabValue] = useState<number>(0);

    const isSmallScreen = useMediaQuery('(max-width:768px)');

    // Query list of restaurant from API with useQuery
    const { data: restaurants, isLoading, isFetching, refetch, error } = useQuery<Restaurant[], AxiosError>({
        queryKey: ["restaurants", page, "pageSize"],
        queryFn: async () => {
            let query = `/restaurant?page=${page}&pageSize=${25}`;
            // If search term exists, add it to the query
            if (searchTerm) {
                query += `&filter=${searchTerm}`;
            }
            // If address search term exists, add it to the query
            if (addressSearchTerm) {
                query += `&address=${addressSearchTerm}`;
            }
            const response = await httpClient.get<RestaurantsResponse>(
                query,
            );
            const totalNumberOfPages = Math.ceil(response.data.totalCount / response.data.pageSize);
            setTotalCount(totalNumberOfPages);
            const restaurants = response.data.items;
            // Find the selected restaurant in the list of restaurants
            const selectedRestaurant: Restaurant | undefined = restaurants.find((restaurant) => restaurant.id === selectedId);
            // If selected restaurant is not in the list of restaurants, set selectedId to undefined
            if (!selectedRestaurant) {
                setSelectedId(undefined);
            }
            // If selected restaurant is in the list of restaurants, set formik values to selected restaurant
            if (selectedRestaurant) {
                // Map over the values one by one, and set the formik values to the selected restaurant values
                formik.setValues({
                    id: selectedRestaurant.id,
                    name: selectedRestaurant.name,
                    address: selectedRestaurant.address,
                    address2: selectedRestaurant.address2 || "",
                    city: selectedRestaurant.city,
                    state: selectedRestaurant.state,
                    zip: selectedRestaurant.zip,
                    country: selectedRestaurant.country,
                    phone: selectedRestaurant.phone,
                    website: selectedRestaurant.website || "",
                    description: selectedRestaurant.description || "",
                    happyHourFoodDetails: selectedRestaurant.happyHourFoodDetails || "",
                    happyHourDrinkDetails: selectedRestaurant.happyHourDrinkDetails || "",
                    happyHourFoodTime: selectedRestaurant.happyHourFoodTime || "",
                    happyHourDrinkTime: selectedRestaurant.happyHourDrinkTime || "",
                    happyHourUrl: selectedRestaurant.happyHourUrl || "",
                    latitude: selectedRestaurant.latitude,
                    longitude: selectedRestaurant.longitude,
                    hoursOfOperation: selectedRestaurant.hoursOfOperation.map((hoursOfOperation) => {
                        return {
                            ...hoursOfOperation,
                            dayOfWeek: hoursOfOperation.dayOfWeek,
                            openTime: convertTimeStampTo12HourTime(hoursOfOperation.openTime),
                            closeTime: convertTimeStampTo12HourTime(hoursOfOperation.closeTime)
                        };
                    })
                });
            }
            return restaurants;
        },
        refetchOnWindowFocus: false,
    });

    const formik = useFormik<RestaurantForm>({
        initialValues: {
            id: "",
            name: "",
            address: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "US",
            phone: "",
            website: "",
            description: "",
            happyHourFoodDetails: "",
            happyHourDrinkDetails: "",
            happyHourFoodTime: "",
            happyHourDrinkTime: "",
            happyHourUrl: "",
            latitude: 0,
            longitude: 0,
            hoursOfOperation: [] as HoursOfOperation[]
        },
        validationSchema: restaurantValidation,
        onSubmit: async (values) => {
            // If form is not valid, do not submit
            if (!formik.isValid) {
                return;
            }
            if (selectedId) {
                try {
                    setUpdateError(null);
                    // Update restaurant with selectedId or create new one
                    const clonedValues = { ...values };
                    // Update hours of operation
                    clonedValues.hoursOfOperation = clonedValues.hoursOfOperation?.map((hour) => {
                        return {
                            ...hour,
                            openTime: convert12HourTimeToUtcTimeStamp(hour.openTime),
                            closeTime: convert12HourTimeToUtcTimeStamp(hour.closeTime),
                        };
                    }) || [];
                    await httpClient.put(`/restaurant`, clonedValues);
                    refetch();
                } catch (error: any) {
                    setUpdateError(error);
                }
            }
            if (newMode) {
                try {
                    setUpdateError(null);
                    // Clone values to avoid mutating formik.values and make id optional
                    const values: Partial<Restaurant> = { ...formik.values };
                    // Remove id so that API will create a new restaurant
                    delete values.id;
                    // Update hours of operation
                    values.hoursOfOperation = values.hoursOfOperation?.map((hour) => {
                        return {
                            ...hour,
                            openTime: convert12HourTimeToUtcTimeStamp(hour.openTime),
                            closeTime: convert12HourTimeToUtcTimeStamp(hour.closeTime),
                        };
                    }) || [];
                    await httpClient.post(`/restaurant`, values);
                    // Reset form
                    formik.resetForm();
                    refetch();
                } catch (error: any) {
                    setUpdateError(error);
                }
            }
        }
    });

    // Refetch when page changes
    useEffect(() => {
        refetch();
    }, [page, refetch]);

    // Refetch when search changes
    useEffect(() => {
        refetch();
    }, [searchTerm, refetch, addressSearchTerm]);

    const handleNewRestaurantClick = () => {
        setSelectedId(undefined);
        formik.resetForm();
        // Reset drop down values for states
        setStates(State.getStatesOfCountry('US') || []);
        setNewMode(true);
    };

    const updateLatLong = async () => {
        try {
            setUpdateError(null);
            await httpClient.post<Restaurant>(`/restaurant/lat-long/${selectedId}`);
            await refetch();
        } catch (error: any) {
            setUpdateError(error);
        }
    }

    // Create a mutation to update happy hour, but use different endpoint, set loading state, and refetch
    const { mutateAsync: updateHappyHour, isPending: isUpdatingHappyHour } = useMutation({
        mutationFn: async () => {
            setUpdateError(null);
            const { data } = await httpClient.post<Restaurant>(`/restaurant/happy-hour/${selectedId}`);
            return data;
        },
        onSuccess: () => {
            refetch();
        },
        onError: (error: any) => {
            setUpdateError(error);
        }
    });

    const { mutateAsync: fetchNewRestaurantInfo, isPending: isFetchingNewRestaurantInfo } = useMutation({
        mutationFn: async () => {
            setUpdateError(null);
            // Find the restaurant in the list of restaurants
            const restaurant = restaurants?.find(r => r.id === selectedId);
            if (!restaurant) {
                return;
            }
            const { data } = await httpClient.post<Restaurant>(`/restaurant/fetch-new-data/${selectedId}`);
            return data;
        },
        onSuccess: () => {
            refetch();
        },
        onError: (error: any) => {
            setUpdateError(error);
        }
    });

    const isDisabled = formik.isSubmitting || isUpdatingHappyHour || isFetchingNewRestaurantInfo || isLoading || isFetching;

    const handleRestaurantSelect = (restaurant: Restaurant | undefined): void => {
        if (!restaurant || isDisabled) {
            return;
        }
        formik.resetForm();
        setSelectedId(restaurant?.id);
        setNewMode(false);
        // Set drop down values for states based on country
        // Find the country in the list of countries
        const country = countries.find(c => c.isoCode === restaurant?.country);
        // If country is found, set the states
        if (country) {
            setStates(State.getStatesOfCountry(country.isoCode) || []);
        }
        if (!restaurant) {
            return;
        }
        formik.setValues({
            ...restaurant,
            id: restaurant.id,
            name: restaurant.name,
            address: restaurant.address,
            address2: restaurant.address2 || "",
            city: restaurant.city,
            state: restaurant.state,
            zip: restaurant.zip,
            country: restaurant.country,
            phone: restaurant.phone,
            website: restaurant.website,
            description: restaurant.description,
            happyHourFoodDetails: restaurant.happyHourFoodDetails,
            latitude: restaurant.latitude,
            longitude: restaurant.longitude,
            hoursOfOperation: restaurant.hoursOfOperation.map(h => {
                return {
                    ...h,
                    openTime: convertTimeStampTo12HourTime(h.openTime),
                    closeTime: convertTimeStampTo12HourTime(h.closeTime)
                };
            })
        });
    };

    return <>
        <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
            <Tab label="Restaurants" />
            <Tab label="Cuisine Types" />
        </Tabs>
        {tabValue === 0 && (
            <>
                <ZipCodeFormDialog
                    open={zipCodeDialogOpen}
                    onClose={() => setZipCodeDialogOpen(false)}
                    onSubmit={(zipCode) => {
                        setZipCodeDialogOpen(false);
                        enqueueSnackbar(`Queued zip code ${zipCode} for processing`, {
                            variant: "success",
                            autoHideDuration: 3000,
                        });
                    }} />
                {error && <Box sx={{
                    // Center error message in the middle of the page
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%"
                }}>
                    <Error error={error} />
                </Box>}
                {(isLoading || isFetching || isUpdatingHappyHour || isFetchingNewRestaurantInfo) && <CircularProgress
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1
                    }}
                    disableShrink />}
                {(restaurants) && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            height: 'calc(100% - 48px)',
                            overflowY: 'auto'
                        }}
                    >
                        {((isSmallScreen && (!selectedId && !newMode)) || !isSmallScreen) && (
                            // If small screen, add class w-full to make it full width
                            <div className={`h-full overflow-y-auto ${isSmallScreen ? "w-full" : ""}`}>
                                <RestaurantAdminList restaurants={restaurants}
                                    disabled={isDisabled}
                                    isSmallScreen={isSmallScreen}
                                    onAction={(action => {
                                        if (action === 'Create') {
                                            handleNewRestaurantClick();
                                        }
                                        if (action === 'Create by Zip') {
                                            setZipCodeDialogOpen(true);
                                        }
                                        if (action === 'Refresh') {
                                            refetch();
                                        }
                                    })}
                                    onRestaurantSelect={handleRestaurantSelect}
                                    onSearch={(search, addressSearch) => {
                                        // Set the page to 1 when searching
                                        setPage(1);
                                        setSearchTerm(search);
                                        setAddressSearchTerm(addressSearch || "");
                                    }}
                                    selectedRestaurantId={selectedId}
                                />
                                {/* Hide pagination if no restaurants exists */}
                                {restaurants.length > 0 && (
                                    <Pagination
                                        disabled={isDisabled}
                                        count={totalCount}
                                        page={page}
                                        onChange={(e, page) => setPage(page)}
                                    />
                                )}
                            </div>
                        )}
                        {(selectedId || newMode) && (
                            // If small screen the make form full width
                            <form onSubmit={formik.handleSubmit}
                                className={`${isSmallScreen ? "w-full h-full overflow-y-auto flex flex-col pl-2 pr-2" : "flex-1 flex flex-col p-3 max-w-2xl mx-auto h-full overflow-y-auto"}`}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: 'column',
                                    width: "100%",
                                    height: "100%",
                                    overflowY: "auto"
                                }}>
                                    <TextField
                                        label="Name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        name="name"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.name && formik.touched.name}
                                        helperText={!!formik.errors.name && formik.touched.name ? formik.errors.name : ""}
                                        onBlur={formik.handleBlur}
                                    />
                                    <TextField
                                        label="Description"
                                        multiline
                                        rows={4}
                                        value={formik.values.description || ""}
                                        onChange={formik.handleChange}
                                        name="description"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.description && formik.touched.description}
                                        helperText={!!formik.errors.description && formik.touched.description ? formik.errors.description : ""}
                                        onBlur={formik.handleBlur}
                                    />
                                    <TextField
                                        label="Happy Hour Drink Time"
                                        value={formik.values.happyHourDrinkTime || ""}
                                        onChange={formik.handleChange}
                                        name="happyHourDrinkTime"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.happyHourDrinkTime && formik.touched.happyHourDrinkTime}
                                        helperText={!!formik.errors.happyHourDrinkTime && formik.touched.happyHourDrinkTime ? formik.errors.happyHourDrinkTime : ""}
                                        onBlur={formik.handleBlur}
                                    />
                                    <TextField
                                        label="Happy Hour Drink Details"
                                        multiline
                                        rows={4}
                                        value={formik.values.happyHourDrinkDetails || ""}
                                        onChange={formik.handleChange}
                                        name="happyHourDrinkDetails"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.happyHourDrinkDetails && formik.touched.happyHourDrinkDetails}
                                        helperText={!!formik.errors.happyHourDrinkDetails && formik.touched.happyHourDrinkDetails ? formik.errors.happyHourDrinkDetails : ""}
                                        onBlur={formik.handleBlur}
                                    />
                                    <TextField
                                        label="Happy Hour Food Time"
                                        value={formik.values.happyHourFoodTime || ""}
                                        onChange={formik.handleChange}
                                        name="happyHourFoodTime"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.happyHourFoodTime && formik.touched.happyHourFoodTime}
                                        helperText={!!formik.errors.happyHourFoodTime && formik.touched.happyHourFoodTime ? formik.errors.happyHourFoodTime : ""}
                                        onBlur={formik.handleBlur}
                                    />
                                    <TextField
                                        label="Happy Hour Food Details"
                                        multiline
                                        rows={4}
                                        value={formik.values.happyHourFoodDetails || ""}
                                        onChange={formik.handleChange}
                                        name="happyHourFoodDetails"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.happyHourFoodDetails && formik.touched.happyHourFoodDetails}
                                        helperText={!!formik.errors.happyHourFoodDetails && formik.touched.happyHourFoodDetails ? formik.errors.happyHourFoodDetails : ""}
                                        onBlur={formik.handleBlur}
                                    />
                                    {/* If happyHourUrl exists show the link, when clicking the link it will open in a new tab */}
                                    {formik.values.happyHourUrl &&
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}>
                                            <Link href={formik.values.happyHourUrl}
                                                underline="hover"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open(formik.values.happyHourUrl, "_blank");
                                                }}
                                            >
                                                View Reference
                                            </Link>
                                        </Box>
                                    }
                                    <HoursOfOperationAccordion
                                        hoursOfOperation={formik.values.hoursOfOperation}
                                        disabled={isDisabled}
                                        onChange={({ isOpen, startTime, endTime }, index) => {
                                            // If the day is open, add it to the hours of operation
                                            if (isOpen) {
                                                // If the day is already in the hours of operation, update it
                                                if (formik.values.hoursOfOperation?.find(h => h.dayOfWeek === index)) {
                                                    formik.setFieldValue("hoursOfOperation", formik.values.hoursOfOperation.map(h => {
                                                        if (h.dayOfWeek === index) {
                                                            return {
                                                                ...h,
                                                                dayOfWeek: index,
                                                                openTime: startTime,
                                                                closeTime: endTime
                                                            }
                                                        }
                                                        return h;
                                                    }));
                                                }
                                                // Otherwise add it
                                                else {
                                                    formik.setFieldValue("hoursOfOperation", [
                                                        ...(formik.values.hoursOfOperation || []),
                                                        {
                                                            ...formik.values.hoursOfOperation,
                                                            dayOfWeek: index,
                                                            openTime: startTime,
                                                            closeTime: endTime
                                                        }
                                                    ]);
                                                }
                                            }
                                            // Otherwise remove it from the hours of operation
                                            else {
                                                const hoursOfOperation = formik.values.hoursOfOperation?.filter(h => h.dayOfWeek !== index);
                                                formik.setFieldValue("hoursOfOperation", hoursOfOperation);
                                            }
                                        }}
                                    />
                                    <TextField
                                        label="Address"
                                        value={formik.values.address}
                                        onChange={formik.handleChange}
                                        name="address"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.address && formik.touched.address}
                                        helperText={!!formik.errors.address && formik.touched.address ? formik.errors.address : ""}
                                        onBlur={formik.handleBlur}
                                    />
                                    <TextField
                                        label="Address 2"
                                        value={formik.values.address2}
                                        onChange={formik.handleChange}
                                        name="address2"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.address2 && formik.touched.address2}
                                        helperText={!!formik.errors.address2 && formik.touched.address2 ? formik.errors.address2 : ""}
                                        onBlur={formik.handleBlur}
                                    />
                                    <TextField
                                        label="City"
                                        value={formik.values.city}
                                        onChange={formik.handleChange}
                                        name="city"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.city && formik.touched.city}
                                        helperText={!!formik.errors.city && formik.touched.city ? formik.errors.city : ""}
                                        onBlur={formik.handleBlur}
                                    />
                                    <FormControl fullWidth variant="filled" margin="normal">
                                        <InputLabel id="country-label">State</InputLabel>
                                        <Select
                                            labelId="state-label"
                                            label="State"
                                            value={formik.values.state}
                                            onChange={formik.handleChange}
                                            name="state"
                                            variant="filled"
                                            disabled={isDisabled}
                                            error={!!formik.errors.state && formik.touched.state}
                                            onBlur={formik.handleBlur}
                                        >
                                            {states.map(state => (
                                                <MenuItem
                                                    key={state.isoCode}
                                                    divider={true}
                                                    value={state.isoCode}
                                                >{state.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth margin="normal" variant="filled">
                                        <InputLabel id="country-label">Country</InputLabel>
                                        <Select
                                            labelId="country-label"
                                            label="Country"
                                            value={formik.values.country}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                const countries = Country.getAllCountries();
                                                const country = countries.find(c => c.isoCode === e.target.value);
                                                const states = State.getStatesOfCountry(country?.isoCode);
                                                formik.setFieldValue("state", "");
                                                setStates(states);
                                            }}
                                            name="country"
                                            variant="filled"
                                            disabled={isDisabled}
                                            error={!!formik.errors.country && formik.touched.country}
                                            onBlur={formik.handleBlur}
                                        >
                                            {countries.map(country => (
                                                <MenuItem
                                                    key={country.isoCode}
                                                    divider={true}
                                                    value={country.isoCode}
                                                >{country.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label="Zip"
                                        value={formik.values.zip}
                                        onChange={formik.handleChange}
                                        name="zip"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.zip && formik.touched.zip}
                                        helperText={!!formik.errors.zip && formik.touched.zip ? formik.errors.zip : ""}
                                        onBlur={formik.handleBlur}
                                        onKeyDown={handleKeyDownForNumbersOnly}
                                    />
                                    <TextField
                                        label="Phone"
                                        value={formik.values.phone || ""}
                                        onChange={formik.handleChange}
                                        name="phone"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.phone && formik.touched.phone}
                                        helperText={!!formik.errors.phone && formik.touched.phone ? formik.errors.phone : ""}
                                        onBlur={formik.handleBlur}
                                        onKeyDown={handleKeyDownForNumbersOnly}
                                    />
                                    <TextField
                                        label="Website"
                                        value={formik.values.website || ""}
                                        onChange={formik.handleChange}
                                        name="website"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.website && formik.touched.website}
                                        helperText={!!formik.errors.website && formik.touched.website ? formik.errors.website : ""}
                                        onBlur={formik.handleBlur}
                                    />
                                    <TextField
                                        label="Latitude"
                                        value={formik.values.latitude || ""}
                                        onChange={formik.handleChange}
                                        name="latitude"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.latitude && formik.touched.latitude}
                                        helperText={!!formik.errors.latitude && formik.touched.latitude ? formik.errors.latitude : ""}
                                        onBlur={formik.handleBlur}
                                        onKeyDown={handleKeyDownForNumbersOnly}
                                    />
                                    <TextField
                                        label="Longitude"
                                        value={formik.values.longitude || ""}
                                        onChange={formik.handleChange}
                                        name="longitude"
                                        variant="filled"
                                        margin="normal"
                                        disabled={isDisabled}
                                        error={!!formik.errors.longitude && formik.touched.longitude}
                                        helperText={!!formik.errors.longitude && formik.touched.longitude ? formik.errors.longitude : ""}
                                        onBlur={formik.handleBlur}
                                        onKeyDown={handleKeyDownForNumbersOnly}
                                    />
                                </Box>
                                {newMode && (
                                    <Button
                                        sx={{
                                            marginBottom: "4px",
                                            marginTop: "4px"
                                        }}
                                        variant="contained"
                                        type="submit"
                                        disabled={isDisabled || !formik.isValid || !formik.dirty}
                                    >
                                        {formik.isSubmitting ? "Creating..." : "Create"}
                                    </Button>
                                )}
                                {!newMode && (
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        paddingTop: "8px",
                                        paddingBottom: "8px"
                                    }}>
                                        <Button
                                            sx={{
                                                marginBottom: "4px",
                                                width: "100%",
                                                marginRight: "8px"
                                            }}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={isDisabled || !formik.isValid || !formik.dirty}
                                        >
                                            {formik.isSubmitting ? "Updating..." : "Update"}
                                        </Button>
                                        {/* Add a delete button, don't show gap for last item */}
                                        <RestaurantActionEllipsis onAction={
                                            (action) => {
                                                switch (action) {
                                                    case 'Delete':
                                                        setDeleteDialogOpen(true);
                                                        break;
                                                    case 'Update Lat/Long':
                                                        updateLatLong();
                                                        break;
                                                    case 'Update Happy Hour':
                                                        updateHappyHour();
                                                        break;
                                                    case "Fetch New Restaurant Info":
                                                        fetchNewRestaurantInfo();
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }
                                        }
                                            disabled={isDeleting || isDisabled}
                                        ></RestaurantActionEllipsis>
                                        <ConfirmDialog id={selectedId as string}
                                            open={deleteDialogOpen}
                                            onConfirm={async () => {
                                                try {
                                                    setIsDeleting(true);
                                                    setUpdateError(null);
                                                    // Delete restaurant with selectedId
                                                    await httpClient.delete(`/restaurant/${selectedId}`);
                                                    // Reset form
                                                    formik.resetForm();
                                                    // Set selectedId to null
                                                    setSelectedId(undefined);
                                                    refetch();
                                                } catch (error: any) {
                                                    setUpdateError(error);
                                                }
                                                setIsDeleting(false);
                                                setDeleteDialogOpen(false);
                                            }}
                                            onCancel={() => {
                                                setDeleteDialogOpen(false);
                                            }}
                                            title="Delete Restaurant"
                                            message="Are you sure you want to delete this restaurant?"
                                        />
                                    </Box>
                                )}
                                {updateError && <Error error={updateError} />}
                                {/* Back button that will clear the selectedId when isSmallScreen is true, make this go to the bottom of the screen */}
                                {isSmallScreen && (
                                    <>
                                        <Box sx={{
                                            display: "flex",
                                            flex: "1"
                                        }} />
                                        <Button
                                            sx={{
                                                marginBottom: "4px",
                                                marginTop: "4px",
                                            }}
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                setNewMode(false);
                                                setSelectedId(undefined);
                                            }}
                                        >
                                            Back
                                        </Button>
                                    </>
                                )}
                            </form>
                        )}
                    </Box>
                )}
            </>
        )}
        {tabValue === 1 && (
            <Box sx={{
                display: "flex",
                height: 'calc(100% - 48px)'
            }}>
                <CuisineTypeAdmin />
            </Box>
        )}
    </>;
};
