import DirectionsIcon from '@mui/icons-material/Directions';
import PhoneIcon from '@mui/icons-material/Phone';
import WebIcon from '@mui/icons-material/Web';
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Typography
} from '@mui/material';
import { useContext, useState } from 'react';
import { UserContext } from '../../Contexts/UserContext';
import { httpClient } from '../../Interceptors/httpClient';
import { CuisineIconList } from '../Icons/Icon';
import { RestaurantDialog } from '../RestaurantDialog/RestaurantDialog';
import { FavoriteIcon } from './FavoriteIcon';
import { haversineDistance } from './haversineDistance';
import { HoursOfOperationButton } from './HoursOfOperationButton';
import { MilesAway } from './MilesAway';
import { RestaurantCardProps } from './RestaurantCardProps';
import { RestaurantHappyHour } from './RestaurantHappyHour';

export const RestaurantCard = (props: RestaurantCardProps) => {
    const { restaurant, location } = props;
    const [open, setOpen] = useState(false);
    const { user, setUser } = useContext(UserContext);
    const [isFavorite, setIsFavorite] = useState<Map<string, boolean>>(new Map());
    let distance: number | undefined = undefined;
    if (location) {
        distance = haversineDistance(
            location.latitude,
            location.longitude,
            restaurant.latitude,
            restaurant.longitude
        );
    }

    const toggleFavorite = async (event: React.MouseEvent<HTMLElement, MouseEvent> | React.TouchEvent<HTMLElement>, restaurantId: string) => {
        if (!user) {
            return;
        }
        if (isFavorite.get(restaurantId)) {
            return;
        }
        setIsFavorite((prev) => {
            const newMap = new Map(prev);
            newMap.set(restaurantId, !newMap.get(restaurantId));
            return newMap;
        });
        try {
            const url = user.favoriteRestaurants?.some((r) => r.id === restaurantId) ? 'favorites/remove' : 'favorites/add';
            const response = await httpClient.post(`${url}`, {
                userId: user?.id,
                restaurantId: restaurantId
            });

            if (response.status === 200) {
                const userRestaurants = user.favoriteRestaurants;
                if (userRestaurants) {
                    if (userRestaurants.some((r) => r.id === restaurantId)) {
                        userRestaurants.splice(userRestaurants.findIndex((r) => r.id === restaurantId), 1);
                    } else {
                        userRestaurants.push(restaurant);
                    }
                } else {
                    user.favoriteRestaurants = [restaurant];
                }
                const newUser = { ...user };
                newUser.favoriteRestaurants = userRestaurants;
                setUser(newUser);
            }
        } catch (error) {
            // Handle error
        }
        setIsFavorite((prev) => {
            const newMap = new Map(prev);
            newMap.set(restaurantId, !newMap.get(restaurantId));
            return newMap;
        });
    };

    return (
        <Box
            sx={{
                ...props.sx,
                position: 'relative'
            }}>
            <Card
                onMouseEnter={() => props.onMouseEnter?.(restaurant)}
                onMouseLeave={() => props.onMouseLeave?.(restaurant)}
                key={restaurant.id}
                sx={{
                    m: 1,
                    width: 400,
                    '@media (max-width:400px)': {
                        width: 350,
                    },
                    transition: 'box-shadow 0.3s ease',
                    boxShadow: (theme) => theme.shadows[1],
                    '&:hover': {
                        boxShadow: (theme) => theme.shadows[3], // Higher elevation on hover
                        cursor: 'pointer',
                    },
                }}
            >
                <CardActionArea
                    disableRipple
                    onClick={() => setOpen(true)}
                    sx={{
                        minHeight: '273px !important',
                        display: 'flex',
                        alignItems: 'flex-start',
                        '&.MuiCardActionArea-root': {
                            '&:hover': {
                                '& .MuiCardActionArea-focusHighlight': {
                                    opacity: 0,
                                },
                            }
                        },
                    }}
                >
                    <CardContent sx={{
                        pb: 0,
                        width: '100%'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'top',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}>
                                    <Typography variant="h5">{restaurant.name}</Typography>
                                </Box>
                                <Typography
                                    variant="subtitle2"
                                    color="text.secondary"
                                    sx={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: 1,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        whiteSpace: 'pre-line',
                                        mb: 0.5,
                                    }}
                                >
                                    {restaurant.address}, {restaurant.city}, {restaurant.state} {restaurant.zip}
                                </Typography>
                            </Box>
                            <FavoriteIcon
                                sx={{
                                    zIndex: 1,
                                }}
                                isUpdating={isFavorite.get(restaurant.id)}
                                isFavorite={user?.favoriteRestaurants?.some((r) => r.id === restaurant.id) ?? false}
                                restaurantId={restaurant.id}
                                onClick={toggleFavorite}
                            />
                        </Box>
                        <RestaurantHappyHour restaurant={restaurant} hideOverflow={true} />
                    </CardContent>
                </CardActionArea>
                <CardActions sx={{
                    minHeight: '83px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{
                        mb: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                    }}>
                        <HoursOfOperationButton restaurant={restaurant} />
                        {restaurant.restaurantCuisines?.length > 0 && (
                            <CuisineIconList cuisines={restaurant.restaurantCuisines} />
                        )}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginLeft: '0 !important'
                    }}>
                        <Button
                            size="small"
                            href={`https://www.google.com/maps/dir/?api=1&destination=${`${restaurant.address}, ${restaurant.city}, ${restaurant.state} ${restaurant.zip}`}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <DirectionsIcon fontSize="small" />
                            <Box sx={{ display: 'inline-flex', ml: 0.5 }}>Directions</Box>
                        </Button>
                        <Button
                            size="small"
                            href={`tel:${restaurant.phone}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <PhoneIcon fontSize="small" />
                            <Box sx={{ display: 'inline-flex', ml: 0.5 }}>Call</Box>
                        </Button>
                        <Button
                            size="small"
                            href={restaurant.website}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <WebIcon fontSize="small" />
                            <Box sx={{ display: 'inline-flex', ml: 0.5 }}>Website</Box>
                        </Button>
                        <Box sx={{ flexGrow: 1 }} />
                        {distance && <MilesAway distance={distance} />}
                    </Box>
                </CardActions>
            </Card>
            <RestaurantDialog restaurant={restaurant} open={open} onClose={() => setOpen(false)} />
        </Box>
    );
};
