import { FormControlLabel, Switch } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import { Box } from "@mui/system";
import { SyntheticEvent, useEffect, useState } from "react";
import { TimeSlotDropDown } from "../../../Components/TimeSlotDropDown/TimeSlotDropDown";
import { DayOfWeek } from "../../../Models/Restaurant/DayOfWeek";
import { convertDayOfWeekToString } from "./convertDayOfWeekToString";

export interface HoursOfOperationControlState {
    isOpen: boolean;
    startTime?: string;
    endTime?: string;
}

export interface HoursOfOperationControlProps {
    dayOfWeek: DayOfWeek;
    isOpen: boolean;
    startTime?: string;
    endTime?: string;
    disabled?: boolean;

    onChange?(state: HoursOfOperationControlState): void;
}

export const HoursOfOperationControl = (props: HoursOfOperationControlProps) => {
    const { dayOfWeek, isOpen, startTime, endTime, onChange } = props;

    const [open, setOpen] = useState(isOpen);

    // When isOpen changes, update the state
    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const onStartTimeSelected = (timeSlot: string): void => {
        if (!onChange) {
            return;
        }

        // Create a state object to pass to the onChange handler
        const state: HoursOfOperationControlState = {
            isOpen: open,
            startTime: timeSlot,
            endTime: endTime
        }
        onChange(state);
    };

    const onEndTimeSelected = (timeSlot: string): void => {
        if (!onChange) {
            return;
        }

        // Create a state object to pass to the onChange handler
        const state: HoursOfOperationControlState = {
            isOpen: open,
            startTime: startTime,
            endTime: timeSlot
        }
        onChange(state);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '8px',
            padding: '8px',
            flex: '1 1 0px'
        }}>
            <h2 style={{ width: '90px' }}
            >{convertDayOfWeekToString(dayOfWeek)}</h2>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={props.disabled}
                            checked={open}
                        />
                    }
                    label={open ? 'Open' : 'Closed'}
                    onChange={(event: SyntheticEvent<Element, Event>) => {
                        const target = event.target as HTMLInputElement;
                        const checked = target.checked;
                        setOpen(checked);
                        if (onChange) {
                            // Create a state object to pass to the onChange handler
                            const state: HoursOfOperationControlState = {
                                isOpen: checked,
                                startTime: checked ? startTime : undefined,
                                endTime: checked ? endTime : undefined
                            };
                            onChange(state);
                        }
                    }}
                />
            </FormGroup>
            {/* Add as spacer */}
            <Box sx={{
                flex: '1 1 0px',
            }} />
            {open &&
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                    <TimeSlotDropDown
                        sx={
                            {
                                width: '150px',
                            }
                        }
                        selectedTimeSlot={startTime ?? ''}
                        onTimeSlotSelected={onStartTimeSelected}
                        label="Open Time"
                        disabled={props.disabled}
                    />
                    <span style={{ width: '32px' }}
                    >TO</span>
                    <TimeSlotDropDown
                        sx={
                            {
                                width: '150px',
                            }
                        }
                        selectedTimeSlot={endTime ?? ''}
                        onTimeSlotSelected={onEndTimeSelected}
                        label="Close Time"
                        disabled={props.disabled}
                    />
                </Box>
            }
        </Box>
    );
};