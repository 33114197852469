import { alpha, createTheme } from "@mui/material";
import { neumorphicShadow } from "./neumorphicShadow";

const primaryColor = "#ff6f61"; // vibrant coral
const secondaryColor = "#ffcc29"; // bright yellow
const warningColor = "#ffa726"; // orange
const errorColor = "#e53935"; // red
const successColor = "#66bb6a"; // green
const textPrimaryColor = "#333333"; // dark gray for primary text
const textSecondaryColor = "#555555"; // medium gray for secondary text
const backgroundDefault = "#f2f4f8"; // light gray for the background
const backgroundPaper = "#ffffff"; // white for paper elements
const shadowColor = "#d1d9e6"; // shadow color

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: backgroundDefault,
      paper: backgroundPaper,
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    warning: {
      main: warningColor,
    },
    error: {
      main: errorColor,
    },
    success: {
      main: successColor,
    },
    text: {
      primary: textPrimaryColor,
      secondary: textSecondaryColor,
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: 14,
    h1: {
      fontSize: "2.5rem",
      fontWeight: 600,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: backgroundPaper,
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "text" },
          style: {
            boxShadow: "none",
          },
        },
      ],
      styleOverrides: {
        root: {
          boxShadow: neumorphicShadow(shadowColor),
          "&:hover": {
            boxShadow: neumorphicShadow(shadowColor, true),
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: backgroundPaper,
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          boxShadow: neumorphicShadow(shadowColor, true),
          borderRadius: 8,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: backgroundPaper,
          boxShadow: neumorphicShadow(shadowColor),
          borderRadius: 8,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: backgroundPaper,
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: backgroundPaper,
          borderRadius: 8,
          borderBottomLeftRadius: 0,
          "& .MuiInputBase-root": {
            boxShadow: neumorphicShadow(shadowColor, true),
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          background: backgroundPaper,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: backgroundPaper,
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          background: backgroundPaper,
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${alpha(textPrimaryColor, 0.12)}`,
        },
        head: {
          backgroundColor: backgroundPaper,
        },
        body: {
          backgroundColor: backgroundPaper,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: backgroundPaper,
          color: textPrimaryColor,
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
  },
});
