import { DayOfWeek } from "../../../Models/Restaurant/DayOfWeek";

export const daysOfWeek = Object.keys(DayOfWeek).filter(d => isNaN(Number(d)));

export const convertDayOfWeekToString = (dayOfWeek: DayOfWeek): string => {
    switch (dayOfWeek) {
        case DayOfWeek.Sunday:
            return 'Sunday';
        case DayOfWeek.Monday:
            return 'Monday';
        case DayOfWeek.Tuesday:
            return 'Tuesday';
        case DayOfWeek.Wednesday:
            return 'Wednesday';
        case DayOfWeek.Thursday:
            return 'Thursday';
        case DayOfWeek.Friday:
            return 'Friday';
        case DayOfWeek.Saturday:
            return 'Saturday';
        default:
            return 'Unknown';
    }
};
