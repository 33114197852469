import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RestaurantCard } from "../../Components/RestaurantCard/RestaurantCard";
import { RestaurantCardSkeleton } from "../../Components/RestaurantCardSkeleton/RestaurantCardSkeleton";
import { RestaurantMap } from "../../Components/RestaurantMap/RestaurantMap";
import { LocationContext } from "../../Contexts/UserLocationContext";
import { httpClient } from "../../Interceptors/httpClient";
import { Restaurant } from "../../Models/Restaurant/Restaurant";

export const RestaurantPage = () => {
    const { restaurantId } = useParams();
    const navigate = useNavigate();
    const { location } = useContext(LocationContext);

    const { data, error, isLoading } = useQuery({
        queryKey: ['restaurant', restaurantId],
        queryFn: () => {
            // If restaurantId is undefined, we don't want to make a request
            // Return the user to the home page
            if (!restaurantId) {
                navigate('/');
                return;
            }
            return getRestaurant(restaurantId);
        }
    });

    const getRestaurant = async (restaurantId: string) => {
        const response = await httpClient.get<Restaurant>(`/restaurant/${restaurantId}`);
        return response.data;
    };

    if (error) {
        return (
            <Box sx={
                {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 1,
                    gap: 2
                }
            }>
                <Typography sx={
                    {
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }
                } variant="h4" component="h1" gutterBottom>
                    Restaurant not found
                </Typography>
                <Typography sx={
                    {
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }
                } variant="h6" component="h1" gutterBottom>
                    Please try again
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={
            {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 1,
            }
        }
        >
            {(isLoading || !data) ? (
                <Box sx={
                    {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: 1,
                    }
                }>
                    <Box sx={
                        {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            gap: 2
                        }
                    }>
                        <Box>
                            <RestaurantCardSkeleton amount={1} />
                        </Box>
                        <Box>
                            <Skeleton variant="rectangular" width={400} height={400} />
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box>
                    <Box sx={
                        {
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            gap: 2
                        }
                    }>
                        <Box>
                            <RestaurantCard location={location} key={restaurantId} restaurant={data} />
                        </Box>
                        {data &&
                            <Box sx={
                                {
                                    width: 500,
                                    height: 500,
                                    // If screen is smaller than 400px make the map smaller
                                    '@media (max-width: 400px)': {
                                        width: 350,
                                        height: 350,
                                    },
                                    mt: 1,
                                }
                            }>
                                {location && (
                                    <RestaurantMap restaurants={[data]} location={location} />
                                )}
                            </Box>
                        }
                    </Box>
                </Box>
            )}
        </Box>
    );
};
