import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { HoursOfOperation } from "../../../Models/Restaurant/HoursOfOperation";
import { HoursOfOperationControl, HoursOfOperationControlState } from "./HoursOfOperationControl";
import { daysOfWeek } from './convertDayOfWeekToString';

export interface HoursOfOperationProps {
    disabled?: boolean;
    hoursOfOperation: HoursOfOperation[];
    onChange?(hoursOfOperation: HoursOfOperationControlState, dayOfWeek: number): void;
}

export const HoursOfOperationAccordion = (props: HoursOfOperationProps) => {
    const { hoursOfOperation, onChange } = props;
    return (
        <Accordion sx={{
            width: "100%",
            marginTop: "1rem"
        }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Hours of Operation</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{
                    display: "flex",
                    flexDirection: 'column',
                    width: "100%",
                    height: "100%",
                    overflowY: "auto"
                }}>
                    {/* Iterate over days of the week */}
                    {daysOfWeek.map((day, index) => (
                        <HoursOfOperationControl
                            disabled={props.disabled}
                            key={day}
                            dayOfWeek={index}
                            isOpen={!!hoursOfOperation?.find(h => h.dayOfWeek === index) || false}
                            startTime={hoursOfOperation?.find(h => h.dayOfWeek === index)?.openTime}
                            endTime={hoursOfOperation?.find(h => h.dayOfWeek === index)?.closeTime}
                            onChange={(state) => {
                                onChange?.(state, index);
                            }}
                        ></HoursOfOperationControl>
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};