import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { useFormik } from "formik";
import { FC, useState } from "react";
import * as Yup from 'yup';
import { Error } from "../../Components/Error/Error";
import { httpClient } from "../../Interceptors/httpClient";

export const ForgotPassword: FC = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [emailSent, setEmailSent] = useState(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email")
            .required("Email is required"),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            setError("");
            try {
                await forgotPassword(values.email);
                setLoading(false);
            } catch (e) {
                setError('An error occurred while sending the email.');
                setLoading(false);
            }
        },
    });

    const forgotPassword = async (email: string) => {
        try {
            const response: AxiosResponse = await httpClient.get(`/identity/sendPasswordResetEmail?email=${email}`);

            const { status } = response;

            if (status === 200) {
                setEmailSent(true);
            }
        } catch (e) {
            setError('An error occurred while sending the email.');
        }
    }

    return (
        emailSent ? (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
            }}>
                <Box>
                    <Typography sx={{ textAlign: 'center' }} variant="h4">Email sent!</Typography>
                    <Alert severity="success">
                        Check your email for a link to reset your password. If you don't see it in a few minutes, check your spam folder.
                    </Alert>
                </Box>
            </Box>
        ) : (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
            }}>
                <Box component={"form"} sx={{
                    m: 1,
                    width: 250
                }}
                    onSubmit={formik.handleSubmit}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <Typography sx={{ textAlign: 'center' }} variant="h3" component="h3">
                            Reset Password
                        </Typography>
                        <Box sx={{ textAlign: 'center' }} component={'p'}>
                            Enter the email associated with your account. We'll send you an email with a instructions on how to reset your password.
                        </Box>
                        <TextField
                            label="Email"
                            name="email"
                            value={formik.values.email}
                            variant="filled"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            margin="normal"
                            disabled={loading}
                            error={!!formik.errors.email && formik.touched.email}
                            helperText={(formik.errors.email && formik.touched.email) ? formik.errors.email : ""}
                            required
                        />
                        {/* Disable button when any field is not valid, loading or has no value */}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!formik.isValid || loading || !formik.values.email}
                        >
                            {loading ? "Sending..." : "Send Instructions"}
                        </Button>
                        <Error error={error} />
                    </Box>
                </Box>
            </Box>
        )
    );
}